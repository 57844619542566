import * as yup from 'yup';
import useFormFactory from '../useFormFactory';
import { useTranslation } from 'react-i18next';
import useUpdateGrower, { GrowerUpdateIn } from '../queries/features/subscription/useUpdateGrower';
import useCountryCode from '../useCountryCode';
import { Subscription } from '../../types/subscription';

const usePersonalDetailsForm = (selectedSubscriptionItem: Subscription) => {
  const { t } = useTranslation('', { keyPrefix: 'PERSONAL_FORM' });
  const { FR, DE } = useCountryCode();

  const country_phone_regex = {
    DE: /^(?:\+49|0)[1-9]\d{1,11}$/,
    FR: /^(\+33|0)[1-9](\d{2}){4}$/,
  };

  const country_mobile_phone_regex = {
    DE: /^$/,
    FR: /^(?:(?:\+|00)33|0)[67]\d{8}$/,
  };

  const { uuid, ...subscriptionDetails } = selectedSubscriptionItem || ({} as Subscription);

  const { mutateAsync, isPending } = useUpdateGrower({});

  const validationSchema = yup.object().shape({
    contact_name: yup
      .string()
      .trim()
      .required(t('FIELDS.CONTACT_NAME.REQUIRED'))
      .min(2, t('FIELDS.CONTACT_NAME.MIN_LENGTH')),
    email: yup
      .string()
      .trim()
      .email(t('FIELDS.EMAIL.INVALID'))
      .required(t('FIELDS.EMAIL.REQUIRED')),
    telephone_no: yup
      .string()
      .matches(DE ? country_phone_regex.DE : FR ? country_phone_regex.FR : /^$/, {
        message: t('FIELDS.TELEPHONE.INVALID'),
        excludeEmptyString: true,
      }),
    mobile_no: yup.lazy(() => {
      return FR
        ? yup
            .string()
            .required(t('FIELDS.MOBILE_NO.REQUIRED'))
            .matches(country_mobile_phone_regex.FR, { message: t('FIELDS.MOBILE_NO.INVALID') })
        : yup.string().required(t('FIELDS.MOBILE_NO.REQUIRED'));
    }),
    farm_name: yup.string().trim(),
    address1: yup.string().trim(),
    postal_code1: yup
      .string()
      .trim()
      .matches(/^[0-9]{5}$/, t('FIELDS.POSTAL_CODE.INVALID')),
    city1: yup.string().trim(),
  });

  const onSubmit = async (data: GrowerUpdateIn) => {
    const growerUpdateIn = {
      uuid,
      ...data,
    };
    try {
      return await mutateAsync(growerUpdateIn);
    } catch (e) {}
  };

  const defaultValues = {
    uuid,
    name: subscriptionDetails.name || '',
    contact_name: subscriptionDetails.contact_name || '',
    email: subscriptionDetails.email || '',
    telephone_no: subscriptionDetails.telephone_no || '',
    mobile_no: subscriptionDetails.mobile_no || '',
    farm_name: subscriptionDetails.farm_name || '',
    address1: subscriptionDetails.address1 || '',
    postal_code1: subscriptionDetails.postal_code1 || '',
    city1: subscriptionDetails.city1 || '',
  };

  const formFactoryProps = {
    defaultValues,
    validationSchema,
    onSubmit,
    mode: 'onChange' as const,
  };

  const form = useFormFactory(formFactoryProps);

  return {
    ...form,
    isLoading: isPending,
  };
};

export default usePersonalDetailsForm;
