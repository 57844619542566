import React from 'react';
import { useTranslation } from 'react-i18next';
import { format, parseISO, isValid } from 'date-fns';
import FormDateInput, { dateFormats, locales } from '../Forms/FormDateInput';
import { CalendarIcon } from '@heroicons/react/24/outline';
import useFormFactory from '../../hooks/useFormFactory';
import * as Yup from 'yup';
import useLocalStorage from '../../hooks/localStorage/useLocalStorage';
import Popover from '../Popover';
import { Button } from '@tcc/labz-design-system';

const styles = {
  button: {
    base: 'flex items-center justify-between gap-2 p-2 hover:bg-gray-50',
    active: 'ring-2 ring-blue-500',
  },
  dateDisplay: {
    base: 'text-right',
    rangeText: 'text-xs text-gray-600 truncate',
    noDate: 'text-xs text-gray-400 italic',
  },
  popoverContent: 'p-4 bg-white border border-gray-300 rounded-md shadow-md',
};

const validationSchema = Yup.object({
  range: Yup.boolean().required(),
  applied: Yup.boolean().required(),
});

const DateFilter = ({ column: { name, label }, onChange }) => {
  const [locale] = useLocalStorage('locale', 'fr');
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = React.useState(false);

  const {
    fields,
    values,
    handleSubmit,
    formState: { isValid: formIsValid },
    reset,
    trigger,
  } = useFormFactory({
    defaultValues: {
      range: false,
      fromDate: null,
      toDate: null,
      applied: false,
    },
    validationSchema,
    onSubmit: (values) => {
      onChange(name, { ...values, applied: true });
      setMenuOpen(false);
    },
  });

  const getFormatted = (dateString) => {
    if (!dateString) return '--/--/--';
    try {
      const date = parseISO(dateString);
      if (!isValid(date)) throw new Error('Invalid date');
      return format(date, dateFormats[locales[locale]]);
    } catch {
      return '--/--/--';
    }
  };

  const handleApply = () => {
    fields.applied.setValue(true);
    handleSubmit();
  };

  const handleReset = () => {
    reset();
    onChange(name, {
      range: false,
      fromDate: null,
      toDate: null,
      applied: false,
    });
    setMenuOpen(false);
  };

  // Popover content component
  const popoverContent = (
    <div>
      <div className="space-y-4">
        <div className="space-y-2">
          <p className="font-medium text-gray-700 text-sm">
            {t('DATE_FILTER.FILTER')} {label} {t('DATE_FILTER.BY')}:
          </p>
          <div className="flex justify-center items-center gap-4">
            <label className="flex items-center gap-2 text-gray-700 text-sm">
              <input
                type="radio"
                checked={!values.range}
                onChange={() => {
                  fields.range.setValue(false);
                  trigger();
                }}
                className="border-gray-300 focus:ring-blue-500 w-4 h-4 text-blue-600"
              />
              {t('DATE_FILTER.NO_DATE')}
            </label>
            <label className="flex items-center gap-2 text-gray-700 text-sm">
              <input
                type="radio"
                checked={values.range}
                onChange={() => {
                  fields.range.setValue(true);
                  trigger();
                }}
                className="border-gray-300 focus:ring-blue-500 w-4 h-4 text-blue-600"
              />
              {t('DATE_FILTER.DATE_RANGE')}
            </label>
          </div>
        </div>

        <div className="space-y-3">
          <FormDateInput
            field={fields.fromDate}
            disabled={!values.range}
            locale={locale}
            label={t('DATE_FILTER.FROM')}
          />
          <FormDateInput
            field={fields.toDate}
            disabled={!values.range}
            locale={locale}
            label={t('DATE_FILTER.TO')}
          />
        </div>

        <div className="flex gap-2 mt-4">
          <Button type="button" onClick={handleReset} variant="outlined" className="grow">
            {t('RESET.TEXT')}
          </Button>
          <Button type="button" onClick={handleApply} disabled={!formIsValid} className="grow">
            {t('GENERIC.SUBMIT')}
          </Button>
        </div>
      </div>
    </div>
  );

  // Trigger button component
  const triggerButton = (
    <button
      type="button"
      className={`${styles.button.base} ${menuOpen ? styles.button.active : ''}`}
      onClick={() => setMenuOpen(true)}
    >
      <div className="flex items-center gap-2">
        {!values.applied ? (
          <span className={styles.dateDisplay.noDate}>--/--/--</span>
        ) : (
          <div className={styles.dateDisplay.base}>
            {values.range ? (
              <>
                <p className={styles.dateDisplay.rangeText}>{getFormatted(values.fromDate)}</p>
                <p className={styles.dateDisplay.rangeText}>{getFormatted(values.toDate)}</p>
              </>
            ) : (
              <p className={styles.dateDisplay.noDate}>{t('DATE_FILTER.NO_DATE')}</p>
            )}
          </div>
        )}
        <CalendarIcon className="w-5 h-5 text-blue-600" />
      </div>
    </button>
  );

  return (
    <Popover
      trigger={triggerButton}
      content={popoverContent}
      closeOnClickOutside={false}
      position="bottom"
      customStyles={{
        popoverContent: styles.popoverContent,
      }}
      offset={8}
      isOpen={menuOpen}
    />
  );
};

export default DateFilter;
