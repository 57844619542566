import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { useStateContext } from '../../utils/stateContext';
import TreatmentFormModal from './TreatmentFormModal';
import PredimaTable from '../PredimaTable';
import { useAgronomyTreatments } from '../../hooks/useAgronomyTreatments';
import { formatDate } from '../../utils/commonFunctions';
import EditableCell from '../Table/EditableCell';
import TableControls from '../Table/TableControls';
import { AgronomyField, Treatment } from '../../hooks/useAgronomySummary';
import useBoolean from '../../hooks/useBoolean';

const columnWidth = `${100 / 15}%`;

const styles = {
  editableColumn: 'border-l border-gray-300',
};

const AgronomyTreatments = () => {
  const { t } = useTranslation();
  const {
    getAllGrowers: { data, refetch },
  } = useStateContext();

  const { tableData, lastUpdated } = useAgronomyTreatments(data);
  const [isFiltering, setIsFiltering] = useState(false);
  const [selectedField, setSelectedField] = useState<AgronomyField>();
  const [selectedTreatment, setSelectedTreatment] = useState<Treatment>();
  const [isTreatmentFormModalOpen, { on: openTreatmentFormModal, off: closeTreatmentFormModal }] =
    useBoolean();

  const handleUpdateTreatment = (rowData, t_window) => {
    const selectedTreatment = rowData?.treatments?.find(
      (treatment) => treatment.t_window === t_window
    );
    setSelectedField(rowData);
    setSelectedTreatment({
      field_id: rowData.field_id,
      ...selectedTreatment,
      t_window,
    });
    openTreatmentFormModal();
  };

  const generateTreatmentColumns = (t_window, color) => [
    {
      name: `${t_window.toLowerCase()}_treatment_date`,
      label: t('AGRONOMY.ACT_DATE'),
      color,
      group: true,
      date: true,
      width: columnWidth,
      className: styles.editableColumn,
      renderer: (rowData) => (
        <EditableCell
          data={rowData}
          value={rowData[`${t_window.toLowerCase()}_treatment_date`]}
          formatter={formatDate}
          onClick={() => handleUpdateTreatment(rowData, t_window)}
        />
      ),
    },
    {
      name: `${t_window.toLowerCase()}_rec_spray_date`,
      label: t('AGRONOMY.REC_DATE'),
      color,
      group: true,
      date: true,
      width: columnWidth,
    },
    {
      name: `${t_window.toLowerCase()}_product`,
      label: t('AGRONOMY.PRODUCT'),
      color,
      search: true,
      group: true,
      width: columnWidth,
    },
  ];

  const columns = [
    {
      name: 'distributor',
      label: t('SUBSCRIPTION.DISTRIBUTOR'),
      color: '#0091DF',
      search: true,
      sticky: true,
      width: columnWidth,
      rowSpan: 2,
    },
    {
      name: 'created_user_name',
      label: t('SUBSCRIPTION.SALES_REP_NAME'),
      color: '#0091DF',
      search: true,
      sticky: true,
      width: columnWidth,
      rowSpan: 2,
    },
    {
      name: 'contact_name',
      label: t('SUBSCRIPTION.GROWER'),
      color: '#0091DF',
      search: true,
      sticky: true,
      width: columnWidth,
      rowSpan: 2,
    },
    {
      name: 'farm_name',
      label: t('SUBSCRIPTION.FARM'),
      color: '#0091DF',
      search: true,
      sticky: true,
      width: columnWidth,
      rowSpan: 2,
    },
    {
      name: 'field_name',
      label: t('GENERIC.FIELD'),
      color: '#0091DF',
      search: true,
      sticky: true,
      width: columnWidth,
      rowSpan: 2,
    },
    {
      name: 'field_area',
      label: t('SUBSCRIPTION.HA'),
      color: '#0091DF',
      search: true,
      sticky: true,
      width: columnWidth,
      rowSpan: 2,
    },
    { name: 'T1', colSpan: 3, groupHeader: true, color: '#02A8A8' },
    { name: 'T2', colSpan: 3, groupHeader: true, color: '#00A6E1' },
    { name: 'T3', colSpan: 3, groupHeader: true, color: '#01849D' },
    ...generateTreatmentColumns('T1', '#02A8A8'),
    ...generateTreatmentColumns('T2', '#00A6E1'),
    ...generateTreatmentColumns('T3', '#01849D'),
  ];

  const handleClose = () => {
    setSelectedField(null);
    setSelectedTreatment(null);
    closeTreatmentFormModal();
  };

  return (
    <>
      <TreatmentFormModal
        selectedField={selectedField}
        selectedTreatment={selectedTreatment as any}
        onClose={handleClose}
        isOpen={isTreatmentFormModalOpen}
      />
      <TableControls
        lastUpdated={lastUpdated}
        onReload={refetch}
        onToggleFilters={() => setIsFiltering(!isFiltering)}
        isFiltering={isFiltering}
      />
      <PredimaTable
        data={tableData}
        columns={columns}
        selectable={false}
        showFilters={isFiltering}
      />
    </>
  );
};

export default AgronomyTreatments;
