import i18n from 'i18next';
const languages = ['en', 'fr', 'de'];
const LanguageSelector = () => {
  const currentLang = i18n.language || 'fr';
  const handleLangChange = (lang) => {
    if (lang === currentLang) return;
    i18n.changeLanguage(lang);
    localStorage.setItem('locale', JSON.stringify(lang));

    window.location.reload();
  };
  return (
    <div className="flex space-x-4 mb-4">
      {languages.map((lang) => {
        const isCurrent = currentLang === lang;
        return (
          <button
            type="button"
            tabIndex={-1}
            key={lang}
            onClick={() => handleLangChange(lang)}
            disabled={isCurrent}
            className={`tabIndex-1 ${isCurrent ? 'text-gray-400 cursor-not-allowed' : 'text-primary-800 hover'}`}
          >
            {lang.toUpperCase()}
          </button>
        );
      })}
    </div>
  );
};
export default LanguageSelector;
