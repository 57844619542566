import { useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../utils/stateContext';
import PredimaTable from '../../PredimaTable';
import { AgronomyField, Treatment, useAgronomySummary } from '../../../hooks/useAgronomySummary';
import TreatmentFormModal from '../TreatmentFormModal';
import BBCHUpdate from '../BBCHUpdate';
import TableControls from '../../Table/TableControls';
import useAgronomySummaryColumns from './useAgronomySummaryColumns';
import useBoolean from '../../../hooks/useBoolean';
import { useTableSelection } from '../../../hooks/useTableSelection';

const Summary = () => {
  const { t } = useTranslation();
  const {
    getAllGrowers: { data, refetch },
  } = useStateContext();

  const { growerFields: tableData = [], lastUpdated } = useAgronomySummary(data);
  const [isFiltering, setIsFiltering] = useState(false);
  const [selectedField, setSelectedField] = useState<AgronomyField>();
  const [selectedTreatment, setSelectedTreatment] = useState<Treatment>();
  const [isBBCHModalOpen, { on: openBBCHModal, off: closeBBCHModal }] = useBoolean();
  const [isTreatmentFormModalOpen, { on: openTreatmentFormModal, off: closeTreatmentFormModal }] =
    useBoolean();

  const {
    selectedItems: selectedFields,
    allSelected,
    handleSelect: handleFieldSelect,
    handleSelectAll,
  } = useTableSelection({
    data: tableData,
    idKey: 'field_id',
  });

  const handleEditBBCH = useCallback(
    (row: AgronomyField) => {
      setSelectedField(row);
      openBBCHModal();
    },
    [setSelectedField, openBBCHModal]
  );

  const handleEditActualSprayDate = useCallback(
    (row: AgronomyField) => {
      setSelectedField(row);
      setSelectedTreatment(row.treatment);
      openTreatmentFormModal();
    },
    [setSelectedField, setSelectedTreatment, openTreatmentFormModal]
  );

  const columns = useAgronomySummaryColumns(handleEditBBCH, handleEditActualSprayDate);

  const handleCloseBBCHModal = () => {
    closeBBCHModal();
    setSelectedField(null);
  };

  const handleCloseTreatmentFormModal = () => {
    setSelectedField(null);
    setSelectedTreatment(null);
    closeTreatmentFormModal();
  };

  return (
    <div className={'flex flex-col w-full '}>
      <TableControls
        lastUpdated={lastUpdated}
        onReload={refetch}
        onToggleFilters={() => setIsFiltering(!isFiltering)}
        isFiltering={isFiltering}
      />
      <PredimaTable
        rowKey={'field_id'}
        data={tableData}
        columns={columns}
        showFilters={isFiltering}
        onSelectAll={handleSelectAll}
        onSelect={handleFieldSelect}
        allSelected={allSelected}
        selectedRows={selectedFields}
      />
      <TreatmentFormModal
        selectedField={selectedField}
        selectedTreatment={selectedTreatment as any}
        onClose={handleCloseTreatmentFormModal}
        isOpen={isTreatmentFormModalOpen}
      />
      <BBCHUpdate
        selectedField={selectedField}
        onClose={handleCloseBBCHModal}
        isOpen={isBBCHModalOpen}
      />
    </div>
  );
};
export default Summary;
