import React, { ReactNode, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { createPortal } from 'react-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { de, fr, enGB } from 'date-fns/locale';
import { parse, format, isValid, startOfDay, parseISO } from 'date-fns';
import { Input } from './Input';
import useLocalStorage from '../../hooks/localStorage/useLocalStorage';

registerLocale('de-DE', de);
registerLocale('fr-FR', fr);
registerLocale('en-EN', enGB);
setDefaultLocale('en-EN');

const classNames = {
  inputContainer: 'flex flex-col w-full relative',
  inputLabel: 'flex font-helvetica text-[#333333]',
  fieldError: 'text-red-600 text-sm absolute right-0 bottom-[-22px]',
  input: 'w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500',
  inputDisabled: 'bg-gray-100 cursor-not-allowed',
};

interface CustomInputProps {
  value?: string;
  onClick?: () => void;
  onChange?: () => void;
  placeholder?: string;
  disabled?: boolean;
}

type FormDateInputProps = {
  label?: string;
  type?: string;
  required?: boolean;
  onFocus?: () => void;
  field: any;
  disabled?: boolean;
  TrailingIcon?: ReactNode;
  locale?: Locale;
};

export type Locale = 'de' | 'fr' | 'de';

export const locales = {
  de: 'de-DE',
  fr: 'fr-FR',
  en: 'en-EN',
};

export const dateFormats = {
  'de-DE': 'dd.MM.yyyy',
  'fr-FR': 'dd/MM/yyyy',
  'en-EN': 'dd/MM/yyyy',
};

export const placeholders = {
  'de-DE': 'TT . MM . JJJJ',
  'fr-FR': 'jj / mm / aaaa',
  'en-EN': 'dd / MM / yyyy',
};

export const getFormatted = (dateString, locale = 'fr', inputFormat = null) => {
  const _inputFormat = inputFormat || dateFormats[locales[locale]];
  try {
    const date = parseISO(dateString);
    if (!isValid(date)) throw new Error('Invalid date');
    return format(date, _inputFormat);
  } catch {
    return '';
  }
};

const FormDateInput: React.FC<FormDateInputProps> = ({
  field,
  label,
  required = true,
  disabled = false,
  locale = 'fr',
}) => {
  const [storedLocale] = useLocalStorage('locale', locale);
  const { name, trigger, setValue, getValues, error } = field;
  const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const _locale = locales[storedLocale];
  const displayFormat = dateFormats[_locale];
  const valueFormat = 'yyyy-MM-dd';
  const placeholderText = placeholders[_locale];

  // Sanitize date value
  const sanitizeDateValue = (value: any): Date | null => {
    if (!value) return null;

    // If it's already a valid Date object
    if (value instanceof Date && isValid(value)) {
      return startOfDay(value);
    }

    // If it's a string, try to parse it
    if (typeof value === 'string') {
      try {
        // Try parsing with the value format (YYYY-MM-DD)
        let parsedDate = parse(value, valueFormat, new Date());
        if (isValid(parsedDate)) {
          return startOfDay(parsedDate);
        }

        // Try parsing with the display format
        parsedDate = parse(value, displayFormat, new Date());
        if (isValid(parsedDate)) {
          return startOfDay(parsedDate);
        }

        // Try parsing as ISO date
        parsedDate = new Date(value);
        if (isValid(parsedDate)) {
          return startOfDay(parsedDate);
        }
      } catch (e) {
        console.warn('Date parsing failed:', e);
      }
    }

    return null;
  };

  // Initialize the date value
  useEffect(() => {
    const initialValue = getValues(name);
    const sanitizedDate = sanitizeDateValue(initialValue);
    setSelectedDate(sanitizedDate);

    // Set the initial value in YYYY-MM-DD format
    if (sanitizedDate) {
      setValue(format(sanitizedDate, valueFormat));
    }
  }, [getValues, name, setValue]);

  useEffect(() => {
    let container = document.getElementById('datepicker-portal-container');
    if (!container) {
      container = document.createElement('div');
      container.id = 'datepicker-portal-container';
      document.body.appendChild(container);
    }
    setPortalContainer(container);

    return () => {
      if (container && !container.children.length) {
        container.remove();
      }
    };
  }, []);

  const handleChange = (date: Date | null) => {
    try {
      if (date) {
        const normalizedDate = startOfDay(date);
        setSelectedDate(normalizedDate);

        // Format date for internal value (YYYY-MM-DD)
        const formattedValue = format(normalizedDate, valueFormat);
        setValue(formattedValue);
      } else {
        setSelectedDate(null);
        setValue(null);
      }
      trigger();
    } catch (error) {
      console.error('Error handling date change:', error);
      setSelectedDate(null);
      setValue(null);
      trigger();
    }
  };

  const CustomInput = React.forwardRef<HTMLInputElement, CustomInputProps>(
    ({ value, onClick, onChange, placeholder, disabled }, ref) => {
      // Convert the internal value format to display format
      let displayValue = value;
      if (value && selectedDate) {
        displayValue = format(selectedDate, displayFormat);
      }

      return (
        <Input
          ref={ref}
          value={displayValue || ''}
          onChange={onChange}
          onClick={!disabled ? onClick : undefined}
          placeholder={placeholder}
          readOnly
          disabled={disabled}
          className={`mt-1 ${disabled ? classNames.inputDisabled : ''}`}
        />
      );
    }
  );

  CustomInput.displayName = 'CustomInput';

  return (
    <div className={classNames.inputContainer}>
      <span className={classNames.inputLabel}>
        {label}
        {required && <>*</>}
      </span>
      {portalContainer && (
        <ReactDatePicker
          selected={selectedDate}
          onChange={handleChange}
          customInput={<CustomInput placeholder="Select a date" disabled={disabled} />}
          locale={_locale}
          placeholderText={placeholderText}
          dateFormat={displayFormat}
          disabled={disabled}
          isClearable
          portalId="datepicker-portal-container"
          popperContainer={({ children }) => createPortal(children, portalContainer)}
          popperClassName="datepicker-popper"
          popperPlacement="bottom-start"
          showTimeSelect={false}
          strictParsing
        />
      )}
      <span className={classNames.fieldError}>{error?.message}</span>
    </div>
  );
};

export default FormDateInput;
