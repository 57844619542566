import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const local = localStorage.getItem('locale') ? JSON.parse(localStorage.getItem('locale')) : null;

i18n.use(initReactI18next).init({
  fallbackLng: 'fr',
  lng: `${local || 'fr'}`,
  resources: {
    en: {
      translations: require('./locales/en/translations.json'),
    },
    fr: {
      translations: require('./locales/fr/translations.json'),
    },
    de: {
      translations: require('./locales/de/translations.json'),
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
});

i18n.languages = ['en', 'fr', 'de'];

export default i18n;
