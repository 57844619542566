import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '@tcc/labz-design-system';

type LanguageSelectProps = {
  field: any;
  options: any;
};

const classNames = {
  inputContainer: 'flex flex-col w-full',
  inputLabel: 'font-helvetica text-[#333333]',
  selectInput: 'w-full',
};

const LanguageSelect: React.FC<LanguageSelectProps> = ({ field, options = [] }) => {
  const { t } = useTranslation();

  const { name, trigger, setValue, getValues } = field;

  const handleChange = ({ value }) => {
    setValue(value);
    trigger(name);
  };

  const value = options.find((option) => option.value === getValues(name)) || null;

  return (
    <div className={classNames.inputContainer}>
      <span className={classNames.inputLabel}>{t('REGISTRATION.LANGUAGE_PREFERENCE')}*</span>
      <Select
        title="Language"
        hideLabel
        onChange={handleChange}
        options={options}
        value={value}
        className={classNames.selectInput}
      />
    </div>
  );
};

export default LanguageSelect;
