import client from '../client';

const User = new (class {
  async addUser() {
    const { data } = await client.POST('/users/register');

    return data;
  }

  async getUserByEmail({ email }: { email: string }) {
    const { data } = await client.GET('/users/email/{email}', {
      params: { path: { email } },
    });

    return {
      data,
    };
  }

  async deleteUser({ id }: { id: number }) {
    const { response } = await client.DELETE('/users/{id}', {
      params: { path: { id } },
    });

    return response;
  }
})();

export default User;
