import { useMemo } from 'react';
import { useStateContext } from '../utils/stateContext';
import { CountryCode } from '../api/user/types';

interface useCountryCodeReturn {
  DE: boolean;
  FR: boolean;
}

const useCountryCode = (): useCountryCodeReturn => {
  const { userDetails } = useStateContext();

  const countryCode = useMemo(() => {
    return userDetails?.distributor?.country?.code;
  }, [userDetails]);

  return {
    DE: countryCode === CountryCode.DE,
    FR: countryCode === CountryCode.FR,
  };
};

export default useCountryCode;
