import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Card from '../../../Layout/Card';
import EditButton from './EditButton';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useStateContext } from '../../../../utils/stateContext';
import { useEffect } from 'react';
import { schemas } from '../../../../api/client';

const styles = {
  tableContainer: 'tablecontainer flex flex-col divide-y divide-gray-200 relative',
  tableTop:
    'tableTop flex justify-between px-4 flex h-[53px] bg-primary-500 font-semibold text-xs text-center items-center text-white break-words sticky top-0 left-0 gap-2',
  tableHeader:
    'tableHeader grid grid-cols-5 gap-4 py-2 px-4 font-semibold text-xs text-center text-gray-500 break-words sticky top-[53px] left-0 bg-white',
  tableRow: 'tableRow grid grid-cols-5 gap-4 py-2 px-4 hover:bg-gray-50',
  fieldValue: 'fieldValue flex items-center justify-center text-xs text-gray-700 text-center',
  fieldName: 'fieldName text-base font-semibold text-gray-600 text-sm',
  footerContainer:
    'footerContainer flex items-center justify-between px-4 py-3 bg-primary-500 sticky bottom-0',
  footerText: 'footerText text-sm text-white',
  selectContainer: 'selectContainer w-32',
  saveButton: 'saveButton ml-4 px-4 py-2 text-sm',
};

interface FieldDetailsProps {
  fields?: schemas['FieldOut'][];
  showContractStatus?: boolean;
  withMargin?: boolean;
  rounded?: boolean;
}

const FieldDetails: React.FC<FieldDetailsProps> = ({
  fields = [],
  withMargin = false,
  rounded,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    offers: { setSelectedFields },
  } = useStateContext();

  useEffect(() => {
    setSelectedFields(fields);
  }, []);

  const handleEditFieldsClick = () => {
    navigate('/home/fielddetails');
  };

  const sanitizeSowingDate = (sowingDate: any): string => {
    if (sowingDate instanceof Date) {
      const day = String(sowingDate.getDate()).padStart(2, '0');
      const month = String(sowingDate.getMonth() + 1).padStart(2, '0');
      const year = sowingDate.getFullYear();
      return `${day}-${month}-${year}`;
    }
    if (typeof sowingDate === 'string') {
      const parsedDate = new Date(sowingDate);
      if (!isNaN(parsedDate.getTime())) {
        const day = String(parsedDate.getDate()).padStart(2, '0');
        const month = String(parsedDate.getMonth() + 1).padStart(2, '0');
        const year = parsedDate.getFullYear();
        return `${day}-${month}-${year}`;
      }
      return sowingDate;
    }
    return 'Invalid Date';
  };

  return (
    <Card
      title={t('FIELD.DETAILS.TITLE')}
      headerContent={<></>}
      className={`h-[605px] overflow-x-clip overflow-y-auto ${rounded ? 'rounded-bl-md' : ''}`}
      margin={withMargin ? 'my-4' : 'm-0'}
      padding="p-0"
    >
      <div className={styles.tableContainer}>
        <div className={styles.tableTop}>
          <div>{t('FIELD.DETAILS.TITLE')}</div>
          <div>
            <EditButton onClick={handleEditFieldsClick} />
          </div>
        </div>
        <div className={styles.tableHeader}>
          <div></div>
          <div>{t('FIELD.VARIETY')}</div>
          <div>{t('FIELD.MIXED')}</div>
          <div>{t('FIELD.TILLAGE_ABR')}</div>
          <div>{t('FIELD.SOWING_DATE')}</div>
        </div>
        {fields.map((field, index) => (
          <div key={field.uuid}>
            <div className="flex flex-col place-items-start mt-2 pl-4">
              <div className={styles.fieldName}>{field.field_name}</div>
              <div className={styles.fieldValue}>{field.area} Ha.</div>
            </div>
            <div key={index} className={styles.tableRow}>
              <div></div>
              <div className={styles.fieldValue}>
                {typeof field.variety === 'object' ? field.variety?.variety_name : field.variety}
              </div>
              <div className={styles.fieldValue}>
                {field.mixed_variety ? (
                  <CheckIcon className="w-5 h-5 text-gray-500" />
                ) : (
                  <XMarkIcon className="w-5 h-5 text-gray-500" />
                )}
              </div>
              <div className={styles.fieldValue}>
                {field.tillage ? (
                  <CheckIcon className="w-5 h-5 text-gray-500" />
                ) : (
                  <XMarkIcon className="w-5 h-5 text-gray-500" />
                )}
              </div>
              <div className={`${styles.fieldValue} whitespace-nowrap`}>
                {sanitizeSowingDate(field.sowing_date)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default FieldDetails;
