import { useStateContext } from '../../../../utils/stateContext';
import { useTranslation } from 'react-i18next';
import { classNames } from './classNames';
import Card from '../../../Layout/Card';
import EditButton from './EditButton';
import { useNavigate } from 'react-router-dom';

const DeliveryDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    offers: { selectedDeliveryDetails },
  } = useStateContext();

  return (
    <Card
      title={`${t('GENERIC.DELIVERY')} ${t('GENERIC.DETAILS')}`}
      icon={<EditButton onClick={() => navigate('/home/deliverydetails')} />}
    >
      <>
        <div className="flex flex-col items-center gap-4 px-4">
          <div className={'flex w-full justify-between'}>
            <span className={'font-light'}>{t('GENERIC.CONTACT')}</span>
            <span>{selectedDeliveryDetails.order_contact_name}</span>
          </div>
          <div className={'flex w-full justify-between'}>
            <span className={'font-light'}>{t('GENERIC.MOBILE_NO')}</span>
            <span>{selectedDeliveryDetails.order_mobile_no}</span>
          </div>
          <div className={'flex w-full justify-between'}>
            <span className={'font-light'}>{t('FIELD.ADDRESS')}</span>
            <span>{selectedDeliveryDetails.order_del_address}</span>
          </div>
          <div className={'flex w-full justify-between'}>
            <span className={'font-light'}>{t('FIRSTMEETING.POSTAL_CODE')}</span>
            <span>{selectedDeliveryDetails.order_del_postal_code}</span>
          </div>
          <div className={'flex w-full justify-between'}>
            <span className={'font-light'}>{t('FIRSTMEETING.CITY')}</span>
            <span>{selectedDeliveryDetails.order_del_city}</span>
          </div>
          <span className={'font-light flex place-self-start'}>
            {t('DELIVERY.ADDITIONAL_INFO')}
          </span>
        </div>
        <p className={classNames.textBlue}>{selectedDeliveryDetails.order_add_info}</p>
      </>
    </Card>
  );
};

export default DeliveryDetails;
