import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ToastContainer } from '@tcc/labz-design-system';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import useLogin from '../../hooks/login/useLogin';
import LoginTitle from '../Layout/LoginTitle';
import FormInput from '../Forms/FormInput';
import LanguageSelector from '../LanguageSelector';

const classNames = {
  loginContainer: 'h-[100vh] bg-white sm:bg-[#e6e9ed] flex flex-col w-full items-center',
  loginForm:
    'flex flex-col p-8 gap-6 mt-12 w-full sm:w-[450px] items-center justify-center bg-white sm:rounded-md sm:shadow-custom-modal ',
  loginTitle: 'mx-auto flex min-h-screen max-w-screen-sm items-center justify-center',
  loginText: 'font-helvetica font-light text-[22px] text-[#333333] font-weight',
  loginNote: 'text-gray-800 text-sm',
  resetPassContainer:
    'gap-2 w-full flex flex-col justify-center items-center sm:flex-row text-gray-800 sm:justify-between sm:align-center',
  registerNowContainer:
    'border-t-2 sm:border-0 gap-2 flex flex-col items-center pt-4 text-[#656568]',
  loginInput: 'w-full',
  inputLabel: 'font-helvetica text-[#333333]',
  loginButton:
    'w-full bg-primary-800 border-primary-800 text-lg font-medium hover:bg-primary-900 hover:border-primary-900 transition-all',
  links: 'text-primary-800 hover:text-primary-600',
  passwordInput: 'w-full',
  fieldError: 'text-red-600 text-sm absolute right-0',
  loginError: 'w-full text-red-600 text-sm absolute top-[-28px] left-[50%] translate-x-[-25%]',
  emailIcon: 'text-neutral-600',
  checkboxContainer: 'flex',
  noAccountText: 'text-xs',
};

const Login = () => {
  const { t } = useTranslation();
  const {
    form: {
      formState: { isSubmitting, isValid },
      fields,
      handleSubmit,
    },
  } = useLogin();

  return (
    <>
      <div className={classNames.loginContainer}>
        <form onSubmit={handleSubmit} className={classNames.loginForm}>
          <LoginTitle />
          <span className={classNames.loginText}>{t('LOGIN.TEXT')}</span>
          <LanguageSelector />
          <div className="relative w-full">
            <FormInput
              label={t('LOGIN.EMAIL')}
              field={fields.email}
              TrailingIcon={<MailOutlineIcon className={classNames.emailIcon} cursor="pointer" />}
              disabled={isSubmitting}
            />
          </div>
          <div className="relative mb-5 w-full">
            <FormInput
              label={t('LOGIN.PASSWORD')}
              field={fields.password}
              type="password"
              disabled={isSubmitting}
            />
          </div>
          <Button
            className={classNames.loginButton}
            type="submit"
            disabled={isSubmitting || !isValid}
          >
            {isSubmitting ? t('LOGIN.LOADING') : t('LOGIN.TEXT')}
          </Button>
          <Link className={`${classNames.links} place-self-start`} to="/passwordreset">
            {t('LOGIN.RESET_PASSWORD')}?
          </Link>
        </form>

        <div className={classNames.registerNowContainer}>
          <span className={classNames.noAccountText}>{t('GENERIC.DONT')}</span>
          <Link className={classNames.links} to="/register">
            {t('REGISTRATION.REGISTER_NOW')}
          </Link>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Login;
