import dayjs from 'dayjs';

export const dateFormat = 'DD-MM-YYYY';
export const ISODateFormat = 'YYYY-MM-DD';

export const formatDateTime = (date) => {
  const dateTimeFormat = 'DD-MM-YYYY | HH:mm';
  return dayjs(date).format(dateTimeFormat);
};

export const formatDate = (date, { inputFormat = dateFormat, outputFormat = dateFormat } = {}) => {
  return dayjs(date, inputFormat).format(outputFormat);
};

export const stringToBoolean = (stringBoolean) => {
  return stringBoolean.toLowerCase() === 'true';
};

export const formatNumber = (value: number, { digits = 2 } = {}) => {
  if (value) return Math.round(value * 10 ** digits) / 10 ** digits;
  return null;
};
//Returns the value of a deeply key of an object
//Receives the data object and the route to the key separated by "."
const getNestedProperty = (obj, path) => {
  return path ? path?.split('.').reduce((acc, part) => acc && acc[part], obj) : null;
};

export const getCellValue = (dataRow, column) => {
  if (column?.valueGetter && typeof column.valueGetter === 'function') {
    return column.valueGetter(dataRow);
  }
  return getNestedProperty(dataRow, column?.name);
};
