import { useMemo, useState } from 'react';
import { schemas } from '../api/client';
import useLocalStorage from './localStorage/useLocalStorage';
import { getFormatted } from '../components/Forms/FormDateInput';

export interface FieldTreatmentsItem {
  field_id: number;
  distributor: string;
  created_user_name: string;
  contact_name: string;
  farm_name: string;
  field_name: string;
  field_area: number;
  t1_treatment_date: string | null;
  t1_rec_spray_date: string | null;
  t1_product: string;
  t2_treatment_date: string | null;
  t2_rec_spray_date: string | null;
  t2_product: string;
  t3_treatment_date: string | null;
  t3_rec_spray_date: string | null;
  t3_product: string;
  treatments;
}

const getTreatmentByWindow = (treatments, window, locale) => {
  const treatment = treatments.find((t) => t.t_window === window);
  return {
    treatment_date: getFormatted(treatment?.treatment_date, locale),
    rec_spray_date: getFormatted(treatment?.rec_spray_date, locale),
    product: treatment?.product || '',
  };
};

export const useAgronomyTreatments = (growerList: schemas['GrowerOut'][] = []) => {
  const [locale] = useLocalStorage('locale', 'fr');
  const [lastUpdated, setLastUpdated] = useState(new Date());

  const tableData = useMemo(() => {
    const result: FieldTreatmentsItem[] = [];
    growerList.forEach((grower) => {
      if (grower.order?.order_fields?.length) {
        grower.order.order_fields.forEach((field) => {
          const t1 = getTreatmentByWindow(field.treatments, 'T1', locale);
          const t2 = getTreatmentByWindow(field.treatments, 'T2', locale);
          const t3 = getTreatmentByWindow(field.treatments, 'T3', locale);

          result.push({
            field_id: field.id,
            distributor: grower?.created_user?.distributor?.name || '',
            created_user_name: grower?.created_user?.name || '',
            contact_name: grower.contact_name,
            farm_name: grower.name,
            field_name: field.field_name,
            field_area: field.area,
            t1_treatment_date: t1.treatment_date,
            t1_rec_spray_date: t1.rec_spray_date,
            t1_product: t1.product,
            t2_treatment_date: t2.treatment_date,
            t2_rec_spray_date: t2.rec_spray_date,
            t2_product: t2.product,
            t3_treatment_date: t3.treatment_date,
            t3_rec_spray_date: t3.rec_spray_date,
            t3_product: t3.product,
            treatments: field.treatments,
          });
        });
      }
    });
    return result;
  }, [growerList]);

  return { tableData, lastUpdated };
};
