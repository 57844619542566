import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import GrowerAPI from '../../../../api/grower';
import { components } from '../../../../api/client/schema';

type OrderDetailsUpdateIn = components['schemas']['OrderDetailsUpdateIn'];

export const useUpdateOrderDetails = () => {
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['updateOrderDetails'],
    mutationFn: async (data: OrderDetailsUpdateIn) => {
      const response = await GrowerAPI.updateOrder({
        ...data,
      });

      if (response.status !== 201) throw new Error(t('ALERT.MSG11'));
      return response;
    },
  });
};
