import { isValid, max, parseISO } from 'date-fns';
import { useMemo, useState } from 'react';
import { getTreatments, getTreatments1 } from '../utils/helpers';
import { ProductItem } from '../types/product';
import { FieldOut, GrowerOut, Subscription } from '../types/subscription';
import useLocalStorage from './localStorage/useLocalStorage';
import { dateFormats, getFormatted, locales, Locale } from '../components/Forms/FormDateInput';

const getLatestObservationDate = (fields: FieldOut[] = [], locale: Locale = 'fr') => {
  if (!fields.length) return null;
  const dateFormat = dateFormats[locales[locale]];
  // Filter and parse valid dates into Date objects
  const dates = fields
    .map((f) => f.observation_date)
    .filter((dateString) => dateString)
    .map((dateString) => parseISO(dateString))
    .filter((date) => isValid(date)); // Ensure parsed date is valid

  if (!dates.length) return null; // If no valid dates, return an empty string

  // Find the latest date
  const latestDate = max(dates);

  // Return the formatted date string using getFormatted
  return latestDate ? getFormatted(latestDate, locale) : null;
};

const useSubscription = (data: GrowerOut[] = []) => {
  const [locale] = useLocalStorage('locale', 'fr');
  const [lastUpdated, setLastUpdated] = useState(new Date());

  const processGrower = (grower: GrowerOut): Subscription => {
    const fields = grower.order?.order_fields || [];

    return {
      // Initial grower data
      ...grower,
      // Required fields - explicitly spread to ensure they exist
      uuid: grower.uuid,
      id: grower.id,
      distributor_name: grower.created_user.distributor?.name || '',
      created_user: grower.created_user,
      contact_name: grower.contact_name,
      status: grower.status || '',
      start_date: getFormatted(grower.start_date, locale),
      app_login: grower.app_login || false,
      name: grower.name || '',

      // Computed fields
      total_area: fields.reduce((sum, field) => sum + (field.area || 0), 0),
      total_price: fields.reduce(
        (sum, field) => sum + field.area * (grower.order?.offer.price || 0),
        0
      ),
      fields,
      latest_observation_date: getLatestObservationDate(fields, locale),
      treatments: fields.reduce(
        (allTreatments, field) => ({
          ...allTreatments,
          ...(field.treatments?.length ? getTreatments(field.treatments) : {}),
        }),
        {}
      ),
      compliance_status: fields.length ? fields.every((f) => f.compliance) : null,
    };
  };

  const { subscriptionList = [], productList = [] } = useMemo(() => {
    if (!data.length) return { subscriptionList: [], productList: [] };

    const subscriptionList: Subscription[] = [];
    const productList: ProductItem[] = [];

    data?.forEach((grower) => {
      subscriptionList.push(processGrower(grower));

      if (grower.order?.product_delivery) {
        productList.push({
          grower_uuid: grower.uuid || '',
          grower_name: grower.contact_name || '',
          total_area: grower.order.order_total_ha || 0,
          ...getTreatments1(grower.order.product_delivery),
        });
      }
    });

    setLastUpdated(new Date());
    return { subscriptionList, productList };
  }, [data]);

  return { subscriptionList, productList, lastUpdated };
};

export default useSubscription;
