import { Box, Typography } from '@mui/material';
import BoxOpen from '../../images/boxopen.png';

export const NoDataAvailable = ({ text1, text2 }) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '2vw 0vw 7vw 0vw',
      }}
    >
      <Typography variant="body2" sx={{ fontSize: '14px' }}>
        {text1}
      </Typography>
      <img src={BoxOpen} alt="nodata" />
      <Typography variant="body2" sx={{ fontWeight: 550, mt: 1 }}>
        {text2}
      </Typography>
    </Box>
  );
};
