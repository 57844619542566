import { useTranslation } from 'react-i18next';
import { Button, ToastContainer } from '@tcc/labz-design-system';
import useGrower from '../../hooks/addGrower/useGrower';
import FormInput from '../Forms/FormInput'; // Assuming you have a FormInput component already
import Shell from '../Layout/shared/Shell';
import FormDateInput from '../Forms/FormDateInput';
import useCountryCode from '../../hooks/useCountryCode';

const classNames = {
  addGrowerContainer: 'h-screen bg-white flex flex-col',
  formContainer: 'border-2 border-gray-200 bg-white mx-4 my-2 rounded-lg',
  header: 'flex items-center border-gray-300 p-2 border-b-2',
  title: 'ml-2 text-lg',
  form: 'flex flex-col p-8 gap-6',
  inputField: 'w-full mb-4',
  button: 'bg-blue-600 hover:bg-blue-700 text-white mt-4',
  loginContainer: 'h-[100vh] bg-white sm:bg-[#e6e9ed] flex flex-col w-full items-center',
  registerForm:
    'flex flex-col p-8 gap-8 mt-12 w-full sm:w-[450px] md:w-[750px] items-center justify-center bg-white sm:rounded-md sm:shadow-custom-modal',
  loginText: 'font-helvetica font-light text-[22px] text-[#333333] font-weight',
  fieldError: 'text-red-600 text-sm',
  loginButton:
    'w-full bg-primary-800 border-primary-800 text-lg font-medium hover:bg-primary-900 hover:border-primary-900 transition-all',
  links: 'text-primary-800 hover:text-primary-600',
  rowContainer: 'w-full flex flex-col gap-8 md:flex-row md:gap-4',
};

const PageContent = () => {
  const { t } = useTranslation('', { keyPrefix: 'GROWER_FORM' });

  const {
    form: {
      fields,
      handleSubmit,
      formState: { isValid, isSubmitting },
    },
    editable,
  } = useGrower();

  const countryCode = useCountryCode();

  return (
    <>
      <div className={classNames.loginContainer}>
        <form onSubmit={handleSubmit} className={classNames.registerForm}>
          <span className={classNames.loginText}>{t('TITLE')}</span>

          <div className={classNames.rowContainer}>
            <FormDateInput field={fields.start_date} label={t('FIELDS.START_DATE.LABEL')} />
            <FormInput field={fields.name} label={t('FIELDS.FARM_NAME.LABEL')} />
          </div>
          <div className={classNames.rowContainer}>
            <FormInput field={fields.contact_name} label={t('FIELDS.NAME.LABEL')} />
            <FormInput field={fields.mobile_no} label={t('FIELDS.MOBILE_NO.LABEL')} trim={true} />
          </div>
          <div className={classNames.rowContainer}>
            <FormInput field={fields.email} label={t('FIELDS.EMAIL.LABEL')} />
            <FormInput
              required={false}
              field={fields.telephone_no}
              label={t('FIELDS.TELEPHONE.LABEL')}
              trim={true}
            />
          </div>

          {countryCode.FR ? (
            <div className={classNames.rowContainer}>
              <FormInput
                field={fields.france_details.no_certiphyto}
                label={`${t('FIELDS.NO_CERTIPHYTO.LABEL')}`}
              />

              <FormDateInput
                field={fields.france_details.date_echeance}
                label={t('FIELDS.DATE_ECHEANCE.LABEL')}
              />
            </div>
          ) : null}

          <span className={`${classNames.loginText} place-self-start`}>
            {t('FARM_ADDRESS_TITLE')}
          </span>
          <div className={classNames.rowContainer}>
            <FormInput field={fields.address1} label={t('FIELDS.ADDRESS.LABEL')} required={false} />
          </div>
          <div className={classNames.rowContainer}>
            <FormInput field={fields.city1} label={t('FIELDS.CITY.LABEL')} />
            <FormInput field={fields.postal_code1} label={t('FIELDS.POSTAL_CODE.LABEL')} />
          </div>

          <Button
            className={classNames.loginButton}
            type="submit"
            disabled={isSubmitting || !isValid}
          >
            {!isSubmitting && <>{editable ? t('UPDATE') : t('SAVE')}</>}
            {isSubmitting && t('FIELDS.LOADING.LABEL')}
          </Button>
        </form>
      </div>
      <ToastContainer />
    </>
  );
};

const AddGrowerForm = () => {
  return (
    <Shell footer={false} nav={false} loading={false}>
      <PageContent />
    </Shell>
  );
};

export default AddGrowerForm;
