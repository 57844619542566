import React, { createContext, useContext, useState } from 'react';
import useOffers, { UseOffersReturn } from '../hooks/offers/useOffers';
import useGetAllGrowers, { GetAllGrowersReturn } from '../hooks/queries/features/useGetAllGrowers';
import { LoginResponse } from '../api/login/types';
import { Subscription } from '../types/subscription';
import { schemas } from '../api/client';
import useLogin, { getUserByEmailResponse } from '../hooks/login/useLogin';

interface StateContextReturn {
  selectedSubscriptionItem: [
    Subscription | null,
    React.Dispatch<React.SetStateAction<Subscription | null>>,
  ];
  offers: UseOffersReturn;
  user: LoginResponse | null;
  userData: LoginResponse | null;
  userDetails: any;
  login: (user: LoginResponse, userDetails: getUserByEmailResponse) => void;
  logout: () => void;
  getAllGrowers: GetAllGrowersReturn;
  notifications: schemas['NotificationOut'][];
  setNotifications: React.Dispatch<React.SetStateAction<schemas['NotificationOut'][]>>;
}

const StateContext = createContext<StateContextReturn | undefined>(undefined);

export function StateProvider({ children }) {
  const [notifications, setNotifications] = useState<schemas['NotificationOut'][]>([]);

  const { user, userData, userDetails, login, logout } = useLogin();

  const getAllGrowers = useGetAllGrowers(userData);
  const offers = useOffers({ distributorId: userDetails?.distributor?.id });
  const selectedSubscriptionItem = useState<Subscription | null>();

  return (
    <StateContext.Provider
      value={{
        selectedSubscriptionItem,
        offers,
        user,
        userData,
        userDetails,
        login,
        logout,
        notifications,
        setNotifications,
        getAllGrowers,
      }}
    >
      {children}
    </StateContext.Provider>
  );
}

export function useStateContext() {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('useStateContext must be used within a StateProvider');
  }
  return context;
}
