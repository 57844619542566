import React from 'react';
import { Modal } from '@tcc/labz-design-system';
import { t } from 'i18next';
import CrossIcon from '../icons/CrossIcon';

interface CustomModalProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  children: React.ReactNode;
}
interface CustomModalProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  children: React.ReactNode;
}
const styles = {
  modalContainer: 'p-4',
  form: 'flex flex-col gap-5 w-full px-4',
  header: 'flex justify-between items-center mb-4',
  headerTitle: 'font-semibold text-sm mt-1',
  inputContainer: 'w-full',
  errorText: 'text-red-500 text-xs',
  tWindowContainer: 'flex items-center gap-4',
  buttonContainer: 'flex items-center justify-evenly mb-2 gap-2',
};

const CustomModal = ({ open, handleClose, title, children }: CustomModalProps) => {
  if (!open) return null;

  return (
    <Modal show={open} onClose={handleClose} className={styles.modalContainer}>
      <div className={styles.header}>
        <span className={styles.headerTitle}>{title}</span>
        <CrossIcon onClick={handleClose} fontSize="small" cursor="pointer" />
      </div>
      {children}
    </Modal>
  );
};

export default CustomModal;
