import { PencilSquareIcon } from '@heroicons/react/24/outline';
import Popover from '../../../Popover';
import { useTranslation } from 'react-i18next';

const EditButton = ({ onClick, color = 'text-white', hoverColor = 'text-gray-300' }) => {
  const { t } = useTranslation();
  return (
    <Popover
      triggerMode="hover"
      customStyles={{
        popoverContent: 'bg-black bg-opacity-40 px-4 rounded-md',
        arrow: 'bg-black border border-0 bg-opacity-40',
      }}
      trigger={
        <PencilSquareIcon
          className={`w-5 h-5 ${color} hover:${hoverColor} cursor-pointer`}
          onClick={onClick}
        />
      }
      content={<span className="p-2 text-white text-sm">{t('GENERIC.EDIT')}</span>}
      position={'bottom'}
      closeOnClickOutside={true}
      closeOnEsc={true}
      animationDuration={200}
      offset={8}
      focusLock={true}
    />
  );
};

export default EditButton;
