import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import client, { schemas } from '../../../api/client';
import { useTranslation } from 'react-i18next';
import { toast } from '@tcc/labz-design-system';

type Grower = schemas['GrowerOut'];

export interface GetAllGrowersReturn {
  data: Grower[] | undefined;
  error: Error | null;
  isLoading: boolean;
  refetch: any;
  isRefetching: boolean;
}

export interface AllGrowersApiResponse<T> {
  data?: T;
  error?: { message: string };
}

const useGetAllGrowers = (userData: any): GetAllGrowersReturn => {
  const { t } = useTranslation();

  return useQuery<Grower[], Error>({
    queryKey: ['allGrowers'],
    queryFn: async (): Promise<Grower[]> => {
      const response: AllGrowersApiResponse<Grower[]> = await client.GET('/all_growers');

      if (response.error) {
        throw new Error(response.error.message);
      }

      return response.data ?? [];
    },
    onError: () => {
      toast.error(t('ALERT.MSG11'));
    },
    enabled: !!userData,
    refetchOnWindowFocus: false,
  } as UseQueryOptions<Grower[], Error>);
};

export default useGetAllGrowers;
