import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../utils/stateContext';
import * as Yup from 'yup';
import { toast, Button } from '@tcc/labz-design-system';
import CustomModal from '../CustomModal';
import useFormFactory from '../../hooks/useFormFactory';
import FormDateInput from '../Forms/FormDateInput';
import { useEffect } from 'react';
import { AgronomyField } from '../../hooks/useAgronomySummary';
import { useUpdateObservationDate } from '../../hooks/queries/agronomy/useUpdateObservationDate';
import { Spinner } from '@tcc/labz-design-system';

interface FormValues {
  field_id: number;
  observation_date: Date;
}

const styles = {
  modalContent: 'p-4',
  form: 'flex flex-col gap-5 w-full px-4',
  header: 'flex justify-between items-center',
  headerTitle: 'font-semibold text-sm mt-1',
  buttonContainer: 'flex items-center justify-evenly my-2',
  button: 'w-1/3 ',
};

interface BBCHUpdateProps {
  selectedField: AgronomyField;
  onClose: () => void;
  isOpen: boolean;
}

const BBCHUpdate = ({ selectedField, onClose, isOpen }: BBCHUpdateProps) => {
  const { t } = useTranslation();
  const {
    getAllGrowers: { refetch },
  } = useStateContext();

  const { mutateAsync: updateObservationDate, isPending } = useUpdateObservationDate();

  const validationSchema = Yup.object().shape({
    observation_date: Yup.string().required(t('VALIDATION.REQUIRED_FIELD')),
  });

  const defaultValues: FormValues = {
    field_id: selectedField?.field_id,
    observation_date: null,
  };

  const onSubmit = async (data: FormValues) => {
    try {
      await updateObservationDate({
        ...data,
      });
      toast.success(t('AGRONOMY.OBSERVATION_DATE_ADDED'));
      handleSuccess();
    } catch (e) {
      toast.error(t('ALERT.MSG11'));
    }
  };

  const handleSuccess = () => {
    refetch();
    handleClose();
  };

  const handleClose = () => {
    reset({});
    onClose();
  };

  const formFactoryProps = {
    defaultValues,
    validationSchema,
    onSubmit,
  };

  const {
    fields,
    handleSubmit,
    formState: { isValid },
    reset,
    values,
  } = useFormFactory(formFactoryProps);

  useEffect(() => {
    fields.field_id.setValue(selectedField?.field_id);
    fields.field_id.trigger();
  }, [selectedField?.field_id]);

  return (
    <CustomModal
      title={t('AGRONOMY.BBCH30')}
      open={isOpen}
      handleClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.modalContent}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <FormDateInput field={fields.observation_date} label={t('AGRONOMY.PICK_DATE')} />
          <div className={styles.buttonContainer}>
            <Button
              type="button"
              onClick={handleClose}
              variant="outlined"
              className={styles.button}
            >
              {t('RESET.CANCEL')}
            </Button>
            <Button type="submit" className={styles.button} disabled={!isValid || isPending}>
              {t('GENERIC.SAVE')}
            </Button>
            {isPending && <Spinner className="w-5 text-primary-500" />}
          </div>
        </form>
      </div>
    </CustomModal>
  );
};

export default BBCHUpdate;
