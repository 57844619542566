import Card from '../../../Layout/Card';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../../utils/stateContext';
import { UserIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';

const PersonalDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    selectedSubscriptionItem: [
      { contact_name, mobile_no, email, telephone_no, app_login, france_details },
    ],
  } = useStateContext();

  const YES = t('GENERIC.YES');
  const NO = t('GENERIC.NO');

  const handleEditPersonalDetailsClick = () => {
    navigate('/home/updatePersonalDetails');
  };

  return (
    <Card
      title={t('PERSONAL.DETAILS')}
      icon={<UserIcon className="w-5 h-5 text-white" />}
      padding={'p-4 pb-4'}
      gap="gap-2"
      margin="m-0"
      iconPosition="start"
      editable
      onEditClick={handleEditPersonalDetailsClick}
    >
      <div className="flex justify-between w-full">
        <span className="font-light">{t('GENERIC.CONTACT')}</span>
        <span>{contact_name || '--'}</span>
      </div>
      <div className="flex justify-between w-full">
        <span className="font-light">{t('GENERIC.MOBILE_NO')}</span>
        <span>{mobile_no || '--'}</span>
      </div>
      <div className="flex justify-between w-full">
        <span className="font-light">{t('GENERIC.TELEPHONE')}</span>
        <span>{telephone_no || '--'}</span>
      </div>
      <div className="flex justify-between w-full">
        <span className="font-light">{t('REGISTRATION.EMAIL')}</span>
        <span>{email || '--'}</span>
      </div>
      <div className="flex justify-between w-full">
        <span className="font-light">{t('LOGIN.TEXT')}</span>
        <span>{app_login ? YES : NO}</span>
      </div>
      {france_details && (
        <>
          <div className="flex justify-between w-full">
            <span className="font-light">{t('REGISTRATION.DATE_ECHEANCE')}</span>
            <span>{france_details?.date_echeance || '--'}</span>
          </div>
          <div className="flex justify-between w-full">
            <span className="font-light">{t('REGISTRATION.NO_CERTIPHYTO')}</span>
            <span>{france_details?.no_certiphyto || '--'}</span>
          </div>
        </>
      )}
    </Card>
  );
};

export default PersonalDetails;
