import Shell from '../../Layout/shared/Shell';
import DynamicTabs from '../../Layout/shared/DynamicTabs';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../utils/stateContext';
import ColumnsLayout from '../../Layout/shared/page/ColumnsLayout';
import FieldDetails from '../reviewDetails/components/FieldDetails';
import Card from '../../Layout/Card';
import Eye from '../../icons/Eye';
import PersonalDetails from './components/PersonalDetails';
import FarmDetails from './components/FarmDetails';
import DeliveryDetails from './components/DeliveryDetails';
import { toast, Select, Button } from '@tcc/labz-design-system';
import { useEffect, useState } from 'react';
import GrowerAPI from '../../../api/grower';
import { useNavigate } from 'react-router-dom';

const styles = {
  container: 'flex flex-col divide-y divide-gray-200',
  header: 'grid grid-cols-5 gap-4 py-2 px-4 bg-gray-100 font-semibold text-sm',
  row: 'grid grid-cols-5 gap-4 py-2 px-4 hover:bg-gray-50',
  value: 'text-xs text-gray-700 text-center flex items-center justify-center',
  fieldName: 'text-base font-semibold text-gray-800',
  footer: 'flex items-center justify-between px-4 py-3 bg-primary-500',
  footerText: 'text-sm text-white',
  select: 'w-32',
  button: 'px-4 py-2 text-sm',
  infoItem: 'flex justify-between items-center w-full',
};

const Overview = () => {
  const { t } = useTranslation();
  const CONTRACT_ONGOING = t('GROWER.CONTRACT_ONGOING');
  const CONTRACT_SIGNED = t('GROWER.CONTRACT_SIGNED');
  const contractStatusOptions = [
    { id: '1', label: CONTRACT_ONGOING, value: 'Ongoing' },
    { id: '2', label: CONTRACT_SIGNED, value: 'Signed' },
  ];

  const {
    selectedSubscriptionItem: [{ uuid, order, contract }],
    getAllGrowers: { refetch },
  } = useStateContext();

  const [status, setStatus] = useState(contract || contractStatusOptions[0].value);
  const [isSaving, setIsSaving] = useState(false);
  const [initialStatus, setInitialStatus] = useState(contract || contractStatusOptions[0].value);

  useEffect(() => {
    setInitialStatus(contract || contractStatusOptions[0].value);
  }, [contract]);

  const Empty = () => <span className="font-normal">--</span>;

  const handleSubmit = async () => {
    try {
      setIsSaving(true);
      const response = await GrowerAPI.updateGrower({ uuid, contract: status });

      if (response.status === 200 || response.status === 201) {
        toast.success(t('ALERT.MSG10'));
        await refetch();
      } else {
        toast.error(t('ALERT.MSG21'));
      }
    } catch (error) {
      toast.error(t('ALERT.MSG21'));
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Card
      title={t('GENERIC.OVERVIEW')}
      gap={'gap-2'}
      iconPosition="start"
      margin="m-0"
      icon={<Eye className="w-5 h-5 text-white" />}
    >
      <div className={styles.infoItem}>
        <span className="font-light">{t('TOTAL.FIELDS')}</span>
        <span className="font-bold text-lg">{order?.order_total_no_fields || <Empty />}</span>
      </div>
      <div className={styles.infoItem}>
        <span className="font-light">{t('TOTAL.HECTARES')}</span>
        <span className="font-bold text-lg">{order?.order_total_ha.toFixed(2) || <Empty />}</span>
      </div>
      <div className={styles.infoItem}>
        <span className="font-light">{t('TOTAL.PRICE')}</span>
        <span className="font-bold text-lg">
          {order?.order_price ? (
            <>
              <span className="text-sm">EUR</span> {order.order_price.toFixed(2)}
            </>
          ) : (
            <Empty />
          )}
        </span>
      </div>
      <div className={styles.infoItem}>
        <span className="font-light">{`${t('GENERIC.CONTRACT')} ${t('GENERIC.STATUS')}`}</span>
        <span className="flex flex-col gap-2 font-bold text-lg">
          <Select
            title=""
            options={contractStatusOptions}
            value={contractStatusOptions.find((option) => status === option.value)}
            onChange={(option) => setStatus(option.value)}
            className={styles.select}
          />
          <Button
            disabled={isSaving || status === initialStatus}
            onClick={handleSubmit}
            className={styles.button}
          >
            {t('GENERIC.SAVE')}
          </Button>
        </span>
      </div>
    </Card>
  );
};

const Page = () => {
  const {
    selectedSubscriptionItem: [{ uuid, order } = { uuid: '', order: {} }],
  } = useStateContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!uuid) {
      navigate('/home');
    }
  }, [uuid, navigate]);

  if (!uuid) return null;

  return (
    <ColumnsLayout>
      {<FieldDetails fields={order?.order_fields} showContractStatus={true} rounded />}
      <div className="gap-4 grid grid-cols-2 grid-rows-[auto] my-[53px] px-4">
        <Overview />
        <PersonalDetails />
        <FarmDetails />
        <DeliveryDetails />
      </div>
    </ColumnsLayout>
  );
};

const Tabs = () => {
  const { t } = useTranslation('', { keyPrefix: 'PAGES.TABS' });
  const GROWER_OVERVIEW = t('GROWER_OVERVIEW');
  const tabs = [
    {
      id: 'grower_overview',
      name: GROWER_OVERVIEW,
      children: <Page />,
    },
  ];

  return <DynamicTabs tabs={tabs} />;
};

const GrowerOverview = () => {
  return (
    <Shell footer={false} loading={false}>
      <Tabs />
    </Shell>
  );
};

export default GrowerOverview;
