import { useState, useMemo, useCallback } from 'react';
import { RowData } from '../components/PredimaTable';

interface UseTableSelectionProps<T> {
  data: T[];
  idKey: keyof T;
}

interface UseTableSelectionReturn<T> {
  selectedItems: T[];
  allSelected: boolean;
  handleSelect: (item: T) => void;
  handleSelectAll: () => void;
  isSelected: (item: T) => boolean;
  clearSelection: () => void;
}

export function useTableSelection<T extends RowData>({
  data,
  idKey,
}: UseTableSelectionProps<T>): UseTableSelectionReturn<T> {
  const [selectedItems, setSelectedItems] = useState<T[]>([]);

  const allSelected = useMemo(() => {
    return data.length > 0 && selectedItems.length === data.length;
  }, [selectedItems.length, data.length]);

  const isSelected = useCallback(
    (item: T) => {
      return selectedItems.some((selectedItem) => selectedItem[idKey] === item[idKey]);
    },
    [selectedItems, idKey]
  );

  const handleSelect = useCallback(
    (item: T) => {
      setSelectedItems((prev) => {
        const isCurrentlySelected = prev.some(
          (selectedItem) => selectedItem[idKey] === item[idKey]
        );

        if (isCurrentlySelected) {
          return prev.filter((selectedItem) => selectedItem[idKey] !== item[idKey]);
        }
        return [...prev, item];
      });
    },
    [idKey]
  );

  const handleSelectAll = useCallback(() => {
    setSelectedItems((prev) => (prev.length === data.length ? [] : [...data]));
  }, [data]);

  const clearSelection = useCallback(() => {
    setSelectedItems([]);
  }, []);

  return {
    selectedItems,
    allSelected,
    handleSelect,
    handleSelectAll,
    isSelected,
    clearSelection,
  };
}
