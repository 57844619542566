import { useTranslation } from 'react-i18next';
import { Checkbox } from '@tcc/labz-design-system';

import Shell from '../../Layout/shared/Shell';
import DynamicTabs from '../../Layout/shared/DynamicTabs';
import BackNextButtons from '../../Layout/checkoffer/BackNextButtons';
import PageTitle from '../../Layout/checkoffer/PageTitle';
import FormInput from '../../Forms/FormInput';
import useDeliveryForm from '../../../hooks/deliveryDetails/useDeliveryForm';

const classNames = {
  formContainer: 'flex flex-col w-2/3 h-full align-center px-10 py-4 pb-10 items-center mx-auto ',
  title: 'title text-2xl font-bold text-primary-800 mb-4',
  subtitle: 'subtitle text-sm text-gray-600 mb-6',
  sectionTitle: 'sectionTitle flex items-center gap-4 mb-6 place-self-start',
  sectionTitleText: 'sectionTitleText font-medium text-primary-700',
  sectionTitleLine: 'sectionTitleLine flex-grow border-t border-primary-700',
  formGrid: 'formGrid grid grid-cols-1 sm:grid-cols-2 gap-x-8 mb-4 w-full',
  checkbox: 'checkbox flex items-center',
  checkboxContainer: 'checkboxContainer flex ml-[-15px] place-self-start',
};

const Page = () => {
  const {
    fields,
    handleSubmit,
    formState: { isValid },
    handleCheckboxChange,
    checked,
    isPending,
  } = useDeliveryForm();

  const { t } = useTranslation();

  return (
    <>
      <form className={classNames.formContainer}>
        <PageTitle>{t('DELIVERY.THANK_YOU')}</PageTitle>
        <div className={classNames.sectionTitle}>
          <span className={classNames.sectionTitleText}>{t('GENERIC.DEL_ADDRESS')}</span>
          <div className={classNames.sectionTitleLine} />
        </div>
        <div className={classNames.checkboxContainer}>
          <Checkbox
            id="same_as"
            value={'same_as'}
            defaultChecked={checked}
            onChange={handleCheckboxChange}
          />
          <p className={classNames.subtitle}>{t('GENERIC.SAME_AS')}</p>
        </div>

        <div className={classNames.formGrid}>
          <FormInput
            field={fields.order_del_address}
            label={t('FIRSTMEETING.ADDRESS')}
            disabled={isPending}
          />
          <FormInput
            field={fields.order_del_city}
            label={t('FIRSTMEETING.CITY')}
            disabled={isPending}
          />
        </div>

        <div className={classNames.formGrid}>
          <FormInput
            field={fields.order_del_postal_code}
            label={t('FIRSTMEETING.POSTAL_CODE')}
            disabled={isPending}
          />
        </div>

        <div className={classNames.formGrid}>
          <FormInput
            field={fields.order_contact_name}
            label={t('GENERIC.CONTACT')}
            disabled={isPending}
          />
          <FormInput
            field={fields.order_mobile_no}
            label={t('GENERIC.MOBILE_NO')}
            disabled={isPending}
          />
        </div>

        <div className={`w-full mb-8`}>
          <FormInput
            field={fields.order_add_info}
            label={t('DELIVERY.ADDITIONAL_INFO')}
            required={false}
            disabled={isPending}
          />
        </div>
        <BackNextButtons nextDisabled={!isValid || isPending} onNextClick={handleSubmit} />
      </form>
    </>
  );
};

const Tabs = () => {
  const { t } = useTranslation('', { keyPrefix: 'PAGES.TABS' });
  const DELIVERY_DETAILS = t('DELIVERY_DETAILS');
  const tabs = [
    {
      id: 'delivery_details',
      name: DELIVERY_DETAILS,
      children: <Page />,
    },
  ];

  return <DynamicTabs tabs={tabs} />;
};

const DeliveryDetails = () => {
  return (
    <Shell footer={false} loading={false}>
      <Tabs />
    </Shell>
  );
};

export default DeliveryDetails;
