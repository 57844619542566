import { useMemo, useState, useEffect } from 'react';
import { getCellValue } from '../utils/commonFunctions';
import useLocalStorage from './localStorage/useLocalStorage';
import { dateFormats, locales } from '../components/Forms/FormDateInput';
import { parse, isValid, compareAsc, compareDesc } from 'date-fns';

type SortDirection = 'asc' | 'desc';

interface DataItem {
  [key: string]: any;
}

const useSortedList = <T extends DataItem>(initialData: T[], columns) => {
  const [locale] = useLocalStorage('locale', 'fr');
  const [data, setData] = useState<T[]>([]);
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [sortBy, setSortBy] = useState<keyof T | null>(null);
  const dateFormat = dateFormats[locales[locale]];

  const handleSort = (property) => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    const newDirection = isAsc ? 'desc' : 'asc';

    setSortDirection(newDirection);
    setSortBy(property);
  };

  const sortingColumn = useMemo(() => {
    if (sortBy) {
      return columns?.find((col) => col.name === sortBy);
    }
    return null;
  }, [sortBy, columns]);

  const sortedData = useMemo(() => {
    if (!sortBy) return data;

    return [...data].sort((a, b) => {
      let aValue = getCellValue(a, sortingColumn);
      let bValue = getCellValue(b, sortingColumn);

      if (aValue && !bValue) {
        return sortDirection === 'asc' ? -1 : 1;
      }

      if (!aValue && bValue) {
        return sortDirection === 'asc' ? 1 : -1;
      }

      if (sortingColumn?.date) {
        const aDate = parse(aValue, dateFormat, new Date());
        const bDate = parse(bValue, dateFormat, new Date());

        if (isValid(aDate) && isValid(bDate)) {
          return sortDirection === 'asc' ? compareAsc(aDate, bDate) : compareDesc(aDate, bDate);
        }
      }

      if (sortingColumn?.boolean) {
        if (aValue === bValue) return 0;
        return aValue ? (sortDirection === 'asc' ? -1 : 1) : sortDirection === 'asc' ? 1 : -1;
      }

      if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;

      return 0;
    });
  }, [data, sortDirection, sortBy, sortingColumn]);

  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData]);

  return { sortedData, handleSort, sortBy, sortDirection };
};

export default useSortedList;
