import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import DateFilter from '../Agronomy/DateFilter';
import RiskFilter from '../Agronomy/RiskFilter';
import TextFilter from '../Agronomy/TextFilter';
import TWindowFilter from '../Agronomy/TWindowFilter';
import BooleanFilter from './BooleanFilter';

const cellStyle = {
  border: 0,
  bgcolor: '#E8F1F7',
  textAlign: 'center',
};

const FilterBar = ({
  columns,
  criteria,
  handleUpdateFilter,
  handleUpdateDateFilter,
  isOpen = false,
  selectable = true,
}) => {
  if (!isOpen) return <></>;

  return (
    <TableRow sx={{ bgcolor: '#E8F1F7', padding: 'none' }}>
      {selectable && <TableCell sx={cellStyle}></TableCell>}
      {columns.map((column, i) => (
        <React.Fragment key={column.name}>
          <TableCell
            sx={{
              ...cellStyle,
              ...(column.sticky
                ? { position: 'sticky', left: `${i * column.width}px`, zIndex: 10 }
                : {}),
            }}
          >
            {column.search && (
              <TextFilter
                key={column.name}
                value={criteria[column.name] || ''}
                onChange={handleUpdateFilter(column.name)}
              />
            )}
            {column.date && (
              <DateFilter
                onChange={handleUpdateDateFilter}
                column={column}
                //criteria={criteria[column.name]}
              />
            )}
            {column.risk && (
              <RiskFilter
                onChange={handleUpdateFilter(column.name)}
                value={criteria[column.name]}
              />
            )}
            {column.treatmentFiter && (
              <TWindowFilter
                onChange={handleUpdateFilter(column.name)}
                value={criteria[column.name]}
              />
            )}
            {column.boolean && (
              <BooleanFilter
                onChange={handleUpdateFilter(column.name)}
                value={criteria[column.name]}
              />
            )}
          </TableCell>
        </React.Fragment>
      ))}
    </TableRow>
  );
};

export default FilterBar;
