import { useMutation } from '@tanstack/react-query';
import { toast } from '@tcc/labz-design-system';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ISODateFormat } from '../../../utils/commonFunctions';
import GrowerAPI from '../../../api/grower';

export const useUpdateTreatment = () => {
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['updateTreatment'],
    mutationFn: async (data: {
      field_id: number;
      t_window: string;
      dose_rate: string;
      treatment_date: string;
    }) => {
      const response = await GrowerAPI.setActualSprayDate({
        ...data,
        dose_rate: parseFloat(data.dose_rate.replaceAll(',', '.')),
        treatment_date: dayjs(data.treatment_date).format(ISODateFormat),
      });

      if (response.status !== 200) throw new Error(t('ALERT.MSG11'));
      return response;
    },
    onError: () => toast.error(t('ALERT.MSG11')),
  });
};
