import { Box, Typography } from '@mui/material';
import React from 'react';
import { ISODateFormat, formatDate } from '../../utils/commonFunctions';
import Dot from '../Layout/Dot';
import Popover from '../Popover';
interface RiskValue {
  date?: string;
  value?: string;
}

interface CardProps {
  riskValues?: RiskValue[];
}

const Card: React.FC<CardProps> = ({ riskValues }) => {
  if (!riskValues) return null;
  return (
    <Box sx={{ padding: 1 }}>
      {riskValues.map((item, index) => (
        <Typography sx={{ fontWeight: 500 }} key={index}>
          {formatDate(item?.date, { inputFormat: ISODateFormat })}{' '}
          <Dot color={item?.value} size="0.5em" />
        </Typography>
      ))}
    </Box>
  );
};

interface RiskCellProps {
  rowData: {
    legend?: Record<string, RiskValue[]>;
    risk?: Record<string, string>;
  };
  columnName: string;
}

const RiskCell: React.FC<RiskCellProps> = ({ rowData, columnName }) => {
  const value = rowData[columnName];
  return (
    <Popover
      triggerMode="hover"
      trigger={<Dot color={value} size="0.7em" />}
      content={value > 0 ? <Card riskValues={rowData.legend?.[columnName]} /> : <span>N/A</span>}
      position="bottom"
      closeOnClickOutside={true}
      closeOnEsc={true}
      animationDuration={200}
      offset={8}
      focusLock={true}
    />
  );
};

export default RiskCell;
