import { useState } from 'react';
import Shell from '../../Layout/shared/Shell';
import DynamicTabs from '../../Layout/shared/DynamicTabs';
import { useTranslation } from 'react-i18next';
import { toast } from '@tcc/labz-design-system';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import OrderSummary from '../../DeliveryDetails/OrderSummary';
import BackNextButtons from '../../Layout/checkoffer/BackNextButtons';
import FieldDetails from './components/FieldDetails';
import DeliveryDetails from './components/DeliveryDetails';
import GrowerDetail from '../reviewDetails/components/GrowerDetail';
import { useStateContext } from '../../../utils/stateContext';
import GrowerAPI from '../../../api/grower';
import { ISODateFormat } from '../../../utils/commonFunctions';
import ColumnsLayout from '../../Layout/shared/page/ColumnsLayout';
import Layout3Cols from '../../Layout/shared/Layout3Cols';
import { schemas } from '../../../api/client';

const Page = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    userDetails,
    selectedSubscriptionItem: [{ uuid }],
    offers: {
      selectedFields,
      totalArea,
      totalPrice,
      selectedOffer,
      selectedDeliveryDetails: {
        address1,
        order_del_address,
        order_del_city,
        order_del_postal_code,
        order_contact_name,
        order_mobile_no,
        order_add_info,
      },
    },
    getAllGrowers: { refetch: fetchGrowers },
  } = useStateContext();

  const { id: offer_id } = selectedOffer;
  const { handleSubmit } = useForm();

  const submit = () => {
    let body: schemas['OrderIn'] = {
      offer_id,
      order_farm_address: address1,
      order_del_address,
      order_del_city,
      order_del_postal_code,
      order_contact_name,
      order_mobile_no,
      order_price: Number(totalPrice),
      order_add_info,
      order_total_ha: totalArea,
      order_total_no_fields: selectedFields.length,
      grower_uuid: uuid,
      created_by: userDetails?.id,
      offer: offer_id,
      //@ts-ignore //TODO: sanitize: in OrderFieldIn, variety is a string, while in Out. is a mapped object.
      order_fields: selectedFields.map(
        ({ centroid_coord, sowing_date, variety = null, ...field }) => {
          return {
            ...field,
            variety: variety,
            sowing_date: dayjs(sowing_date).format(ISODateFormat),
            longitude: centroid_coord.split(', ')[0],
            latitude: centroid_coord.split(', ')[1],
          };
        }
      ),
    };
    setIsSubmitting(true);
    GrowerAPI.addOrder(body)
      .then((response) => {
        if (response.status === 201) {
          toast.success(t('ALERT.MSG18'));
          setTimeout(() => {
            navigate('/home');
          }, 2000);
          return;
        } else {
          toast.error(t('ALERT.MSG12'));
        }
      })
      .catch((err) => {
        toast.error(t('ALERT.MSG12'));
        console.error(err);
      })
      .finally(() => {
        setIsSubmitting(false);
        fetchGrowers();
      });
  };

  return (
    <ColumnsLayout>
      <OrderSummary />
      <form noValidate onSubmit={handleSubmit(submit)} className="pb-4">
        <Layout3Cols title={t('REVIEW.NOTE')}>
          <GrowerDetail />
          <FieldDetails fields={selectedFields} withMargin />
          <DeliveryDetails />
        </Layout3Cols>
        <BackNextButtons onNextClick={handleSubmit(submit)} loading={isSubmitting} />
      </form>
    </ColumnsLayout>
  );
};

const Tabs = () => {
  const { t } = useTranslation('', { keyPrefix: 'PAGES.TABS' });
  const REVIEW_DETAILS = t('REVIEW_DETAILS');

  const tabs = [
    {
      id: 'review_details',
      name: REVIEW_DETAILS,
      children: <Page />,
    },
  ];

  return <DynamicTabs tabs={tabs} />;
};

const ReviewDetails = () => {
  return (
    <Shell footer={false} loading={false}>
      <Tabs />
    </Shell>
  );
};

export default ReviewDetails;
