import { useQueryClient, useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ISODateFormat } from '../../../utils/commonFunctions';
import GrowerAPI from '../../../api/grower';

export const useUpdateObservationDate = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['updateObservationDate'],
    mutationFn: async (data: { field_id: number; observation_date: Date }) => {
      const response = await GrowerAPI.addObservationDate(data.field_id, {
        observation_date: dayjs(data.observation_date).format(ISODateFormat),
      });

      if (response.status !== 200) throw new Error(t('ALERT.MSG11'));
      return response;
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['allGrowers'] }),
  });
};
