import {
  useForm,
  UseFormRegister,
  UseFormTrigger,
  UseFormGetValues,
  UseFormSetValue,
  FieldValues,
  DefaultValues,
  Path,
  SubmitHandler,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useMemo } from 'react';

type CreateFormProps<T extends FieldValues> = {
  defaultValues: DefaultValues<T>;
  validationSchema: Yup.ObjectSchema<any>;
  onSubmit: SubmitHandler<T>;
};

type FieldProps<T extends FieldValues> = ReturnType<UseFormRegister<T>> & {
  error?: any;
  trigger: UseFormTrigger<T>;
  getValues: UseFormGetValues<T>;
  setValue: any;
};

type NestedFieldProps<T extends FieldValues> = {
  [K in keyof T]: T[K] extends object ? NestedFieldProps<T[K]> : FieldProps<T>;
};

const createNestedFields = <T extends FieldValues>(
  obj: any,
  register: UseFormRegister<T>,
  formState: any,
  trigger: UseFormTrigger<T>,
  getValues: UseFormGetValues<T>,
  setValue: UseFormSetValue<T>,
  prefix = ''
): any => {
  return Object.keys(obj).reduce((acc, key) => {
    const fieldName = prefix ? `${prefix}.${key}` : key;
    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      acc[key] = createNestedFields(
        obj[key],
        register,
        formState,
        trigger,
        getValues,
        setValue,
        fieldName
      );
    } else {
      acc[key] = {
        ...register(fieldName as Path<T>),
        error: formState.errors[fieldName],
        trigger: () => trigger(fieldName as Path<T>),
        getValues,
        setValue: (value) => setValue(fieldName as Path<T>, value),
        value: getValues()[fieldName],
      };
    }
    return acc;
  }, {});
};

const useCreateForm = <T extends FieldValues>({
  defaultValues,
  validationSchema,
  onSubmit,
}: CreateFormProps<T>) => {
  const { register, handleSubmit, formState, reset, setValue, getValues, trigger, control } =
    useForm<T>({
      resolver: yupResolver(validationSchema),
      defaultValues: useMemo(() => defaultValues, [defaultValues]),
    });

  const fields = useMemo(() => {
    return createNestedFields(
      defaultValues,
      register,
      formState,
      trigger,
      getValues,
      setValue
    ) as NestedFieldProps<T>;
  }, [register, trigger, getValues, setValue, defaultValues, formState]);

  return {
    fields,
    formState,
    handleSubmit: handleSubmit(onSubmit),
    reset,
    values: getValues(),
    control,
    trigger,
  };
};

export default useCreateForm;
