import React from 'react';
import { Spinner } from '@tcc/labz-design-system';
import EditButton from '../Pages/reviewDetails/components/EditButton';

const styles = {
  container: 'flex justify-center items-center gap-2',
  text: 'text-sm whitespace-nowrap',
  icon: 'w-5 h-5 text-gray-500 cursor-pointer hover:text-primary-500 transition-colors duration-200',
  popoverContent: {
    popoverContent: 'bg-black bg-opacity-40 px-4 rounded-md',
    arrow: 'bg-black border border-0 bg-opacity-40',
  },
  editLegend: 'text-white text-xs',
  spinner: 'w-5 h-5',
};

interface EditableCellProps<T> {
  data: T;
  value?: string | null;
  formatter?: (value: any) => string;
  onClick: (data: T) => void;
  tooltipText?: string;
  iconComponent?: React.ReactNode;
  className?: string;
  popoverPosition?: 'top' | 'bottom' | 'left' | 'right';
  customStyles?: {
    container?: string;
    text?: string;
    icon?: string;
    popover?: {
      popoverContent?: string;
      arrow?: string;
    };
  };
  isLoading?: boolean;
}

function EditableCell<T>({
  data,
  value,
  formatter = (val) => String(val),
  onClick,
  tooltipText = 'Edit',
  iconComponent,
  className = '',
  popoverPosition = 'bottom',
  customStyles = {},
  isLoading = false,
}: EditableCellProps<T>) {
  const mergedStyles = {
    container: `${styles.container} ${customStyles.container || ''}`,
    text: `${styles.text} ${customStyles.text || ''}`,
    icon: `${styles.icon} ${customStyles.icon || ''}`,
    popover: {
      popoverContent: customStyles.popover?.popoverContent || styles.popoverContent.popoverContent,
      arrow: customStyles.popover?.arrow || styles.popoverContent.arrow,
    },
  };

  return (
    <div className={`${mergedStyles.container} ${className}`}>
      {value && <span className={mergedStyles.text}>{formatter(value)}</span>}
      {isLoading ? (
        <Spinner className={styles.spinner} />
      ) : (
        <EditButton
          onClick={() => onClick(data)}
          color="text-gray-800"
          hoverColor="text-primary-500"
        />
      )}
    </div>
  );
}

export default EditableCell;
