import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { schemas } from '../../../../api/client';
import { useTranslation } from 'react-i18next';
import GrowerAPI from '../../../../api/grower';

export type Grower = schemas['GrowerOut'];
export type GrowerUpdateIn = schemas['GrowerUpdateIn'];

export interface UpdateGrowerApiResponse {
  status: number;
  data?: Grower;
  error?: string;
}

export type UseUpdateGrowerReturn = UseMutationResult<
  UpdateGrowerApiResponse,
  Error,
  GrowerUpdateIn,
  unknown
>;

export interface UseUpdateGrowerOptions {
  onSuccess?: () => void;
}

const useUpdateGrower = ({
  onSuccess = () => {},
}: UseUpdateGrowerOptions): UseUpdateGrowerReturn => {
  const { t } = useTranslation();

  return useMutation<UpdateGrowerApiResponse, Error, GrowerUpdateIn>({
    mutationFn: async (updateBody: GrowerUpdateIn): Promise<UpdateGrowerApiResponse> => {
      const response = await GrowerAPI.updateGrower(updateBody);
      try {
        return response;
      } catch (error) {
        throw new Error(error instanceof Error ? error.message : 'Unknown error occurred');
      }
    },
  });
};

export default useUpdateGrower;
