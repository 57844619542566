import React from 'react';
import { useTranslation } from 'react-i18next';
import SoilTypeSelect from '../../Forms/SoilTypeSelect';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Switch,
  Typography,
  Select,
} from '@mui/material';
import { ISODateFormat } from '../../../utils/commonFunctions';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { dateFormat } from '../../../utils/commonFunctions';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import VarietiesSelect from '../../Forms/VarietiesSelect';
import RegionSelect from '../../Forms/RegionSelect';
import { DefaultValues, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, useImperativeHandle, useMemo } from 'react';
import { components } from '../../../api/client/schema';
import useCountryCode from '../../../hooks/useCountryCode';
import { Checkbox } from '@tcc/labz-design-system';
import { FieldOut } from '../../../types/subscription';

const classNames = {
  formContainer: 'formContainer mt-4 border border-text-3 rounded-md',
  form: 'flex flex-col mt-4',
  formRow: 'flex gap-4 justify-between px-4',
  headerSection: 'w-[33%]',
  fieldHeader: 'flex gap-4 text-sm py-2 bg-[#F0F9FD] px-4',
  fieldDivider: 'border-r border-gray-300',
  formDivider: 'border-t border-gray-300 my-2',
  formGrid: 'flex gap-4',
  soilInfoBox: 'bg-blue-50 p-4 mt-2',
  soilInfoTitle: 'font-medium text-sm',
  soilInfoText: 'text-sm',
};

const cropTypeTranslations = {
  Wheat: {
    en: 'Wheat',
    fr: 'Blé',
    de: 'Weizen',
  },
  Barley: {
    en: 'Barley',
    fr: 'Orge',
    de: 'Gerste',
  },
  'Grain Corn': {
    en: 'Grain Corn',
    fr: 'Mais Grain',
    de: 'Körnermais',
  },
  'Corn silage': {
    en: 'Corn silage',
    fr: 'Mais Ensilage',
    de: 'Maissilage',
  },
  'Oilseed rape': {
    en: 'Oilseed rape',
    fr: 'Colza',
    de: 'Raps',
  },
  Peas: {
    en: 'Peas',
    fr: 'Pois',
    de: 'Erbsen',
  },
  Sunflower: {
    en: 'Sunflower',
    fr: 'Tournesol',
    de: 'Sonnenblume',
  },
  Sorghum: {
    en: 'Sorghum',
    fr: 'Sorgho',
    de: 'Sorghum',
  },
  Others: {
    en: 'Others',
    fr: 'Autres',
    de: 'Andere',
  },
};

type FieldFormValues = {
  region: string;
  variety: string;
  mixed_variety: boolean;
  soil_type_id: number;
  sowing_date: Date;
  previous_crop: string;
  tillage: boolean;
  soil_type: components['schemas']['SoilTypeOut'] | null;
};

interface FieldFormProps {
  field: FieldOut;
  varieties: any[];
  soilTypes: any[];
}

export interface FieldFormRef {
  triggerValidation: () => Promise<{ isValid: boolean; data: FieldFormValues }>;
}

const FieldForm = forwardRef<FieldFormRef, FieldFormProps>(
  ({ field, varieties, soilTypes }, ref) => {
    const { t, i18n } = useTranslation();
    const local = localStorage.getItem('locale')
      ? JSON.parse(localStorage.getItem('locale'))
      : null;
    const editable = true;

    const countryCode = useCountryCode();

    const validationSchema = Yup.object().shape({
      region: Yup.lazy(() => {
        return !countryCode.DE
          ? Yup.string().required(t('FIELD.DETAILS.REGION.REQUIRED'))
          : Yup.string().notRequired();
      }),
      variety: Yup.string().required(t('FIELD.DETAILS.VARIETY.REQUIRED')),
      mixed_variety: Yup.boolean(),
      soil_type_id: Yup.lazy(() => {
        return !countryCode.DE
          ? Yup.number().required(t('FIELD.DETAILS.SOIL_TYPE.REQUIRED'))
          : Yup.number().notRequired();
      }),
      sowing_date: Yup.date()
        .required(t('FIELD.DETAILS.SOWING_DATE.REQUIRED'))
        .min(new Date('2024-09-11'), t('FIELD.DETAILS.SOWING_DATE.MIN'))
        .max(new Date('2025-01-09'), t('FIELD.DETAILS.SOWING_DATE.MAX')),
      previous_crop: Yup.string().required(t('FIELD.DETAILS.PREVIOUS_CROP.REQUIRED')),
      tillage: Yup.boolean(),
    });

    const defaultValues: DefaultValues<FieldFormValues> = useMemo(
      () => ({
        region: field.region || '',
        variety: field.variety?.variety_name,
        mixed_variety: field.mixed_variety || false,
        soil_type_id: field.soil_type?.id,
        sowing_date: field.sowing_date ? new Date(field.sowing_date) : null,
        previous_crop: field.previous_crop || '',
        tillage: field.tillage || false,
      }),
      [field]
    );

    const {
      formState: { errors },
      setValue,
      getValues,
      watch,
      trigger,
    } = useForm({
      mode: 'onChange',
      resolver: yupResolver(validationSchema),
      defaultValues: useMemo(() => defaultValues, [defaultValues]),
    });

    useImperativeHandle(ref, () => ({
      triggerValidation: async () => {
        const result = await trigger();
        const formData = getValues() as FieldFormValues;
        return { isValid: result, data: { ...field, ...formData } };
      },
    }));

    watch('region');
    watch('variety');
    watch('previous_crop');
    watch('mixed_variety');
    watch('tillage');
    watch('sowing_date');
    watch('soil_type_id');
    const sowing_date = getValues('sowing_date');

    const varietyValue = getValues('variety');

    const getCropTypeTranslation = (cropType) => {
      const locale = i18n.language || 'en';
      return cropTypeTranslations[cropType][locale] || cropType;
    };

    return (
      <div className={classNames.formContainer}>
        <div className={classNames.fieldHeader}>
          <span className={classNames.headerSection}>
            {t('GENERIC.FIELD')} <br />
            <b>{field.field_name}</b>
          </span>
          <span className={classNames.fieldDivider} />
          <span className={classNames.headerSection}>
            {`${t('GENERIC.SIZE')} (HA)`} <br />
            {field.area}
          </span>
          <span className={classNames.fieldDivider} />
          <span className={classNames.headerSection}>
            {t('GROWER.CROP_TYPE')} <br />
            {t('FIELD.DETAILS.WHEAT')}
          </span>
        </div>
        <form className={classNames.form}>
          <div className={classNames.formRow}>
            <VarietiesSelect
              className={'w-1/6'}
              varieties={varieties}
              value={varietyValue}
              onChange={(_, variety) => setValue('variety', variety)}
              error={!!errors.variety}
            />
            {!countryCode.DE && (
              <>
                <RegionSelect
                  className={'w-1/6'}
                  value={getValues('region')}
                  onChange={(_, region) => {
                    setValue('region', region);
                    setValue('soil_type_id', null);
                  }}
                  soilTypes={soilTypes}
                  error={!!errors.region}
                />
                <SoilTypeSelect
                  className={'w-2/6'}
                  value={getValues('soil_type_id')}
                  soilTypes={soilTypes}
                  error={!!errors.soil_type_id}
                  onChange={(newValue) => {
                    setValue('soil_type_id', newValue);
                  }}
                  region={getValues('region')}
                />
              </>
            )}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                className={'w-1/6'}
                label={`${t('FIELD.SOWING_DATE')}*`}
                value={
                  sowing_date === null
                    ? dayjs(null)
                    : editable
                      ? dayjs(sowing_date)
                      : (dayjs(sowing_date) as any)
                }
                format={dateFormat}
                minDate={dayjs('12-09-2024', dateFormat) as any}
                maxDate={dayjs('09-01-2025', dateFormat) as any}
                onChange={(newValue) => {
                  const dateOnly = newValue ? newValue.startOf('day').format(ISODateFormat) : null;
                  setValue('sowing_date', dateOnly);
                }}
                slotProps={{
                  textField: {
                    size: 'small',
                    fullWidth: false,
                    error: errors.sowing_date ? true : false,
                  },
                }}
              />
            </LocalizationProvider>
            <FormControl size="small" className={'w-1/6'}>
              <InputLabel>{`${t('FIELD.PREVIOUS_CROP')}*`}</InputLabel>
              <Select
                value={getValues('previous_crop') || ''}
                label={`${t('FIELD.PREVIOUS_CROP')}*`}
                name="previous_crop"
                onChange={(s) => setValue('previous_crop', s.target.value)}
                error={!!errors.previous_crop}
              >
                {Object.keys(cropTypeTranslations).map((cropType) => (
                  <MenuItem key={cropType} value={cropType}>
                    {getCropTypeTranslation(cropType)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size="small" className={'w-2/6'}>
              <InputLabel>{`${t('FIELD.TILLAGE')}*`}</InputLabel>
              <Select
                className={'min-w-[200px]'}
                value={getValues('tillage') ? 'Yes' : 'No'}
                label={`${t('FIELD.TILLAGE')}*`}
                name="previous_crop"
                onChange={(a) => setValue('tillage', !getValues('tillage'))}
              >
                <MenuItem value="Yes">{t('GENERIC.YES')}</MenuItem>
                <MenuItem value="No">{t('GENERIC.NO')}</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={classNames.formRow}>
            <FormControlLabel
              className="my-2"
              control={
                <Checkbox
                  id="mixed_variety"
                  value={null}
                  defaultChecked={getValues('mixed_variety') || false}
                  onChange={(event, newValue) => setValue('mixed_variety', newValue)}
                />
              }
              label={<Typography sx={{ fontSize: '12px' }}>{t('FIELD.MIXED_VARIETY')}</Typography>}
            />
          </div>
        </form>
      </div>
    );
  }
);

export default FieldForm;
