import React from 'react';
import { Button } from '@tcc/labz-design-system';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const classNames = {
  buttonsContainer: 'flex justify-center gap-4',
  proceedButton: 'min-w-[200px] place-self-end',
  backButton: 'min-w-[200px] place-self-start bg-primary-500',
};

interface BackNextButtonProps {
  type?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  onNextClick: () => void;
  nextDisabled?: boolean;
  backDisabled?: boolean;
}

const BackNextButtons = ({
  loading = false,
  onNextClick,
  nextDisabled = false,
  backDisabled = false,
  type = 'button',
}: BackNextButtonProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className={classNames.buttonsContainer}>
      <Button
        disabled={loading || backDisabled}
        onClick={() => navigate(-1)}
        className={classNames.backButton}
        type="button"
      >
        {t('OFFERS.BUTTONS.BACK')}
      </Button>
      <Button
        disabled={loading || nextDisabled}
        onClick={onNextClick}
        className={classNames.proceedButton}
        type={type}
      >
        {loading ? t('OFFERS.BUTTONS.UPDATING') : t('OFFERS.BUTTONS.PROCEED')}
      </Button>
    </div>
  );
};

export default BackNextButtons;
