import React, { useState, useRef, useEffect, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useFloating, flip, shift, arrow, offset, type Placement } from '@floating-ui/react';

const styles = {
  container: 'relative inline-block',
  popover: 'fixed z-50  transform',
  arrow: 'absolute z-1 w-2 h-2 bg-white border border-gray-200',
  popoverContent:
    'relative z-10 bg-white rounded-lg shadow-lg border border-gray-200 p-4 focus:outline-none', // Added relative
  arrowTop: 'left-1/2 -translate-x-1/2 -bottom-1 border-t-0 border-l-0',
  arrowBottom: 'left-1/2 -translate-x-1/2 -top-1 border-b-0 border-r-0',
  arrowLeft: 'top-1/2 -translate-y-1/2 -right-1 border-t-0 border-r-0',
  arrowRight: 'top-1/2 -translate-y-1/2 -left-1 border-b-0 border-l-0',
  // Animation classes
  enter: 'transition-opacity duration-200 ease-out',
  enterFrom: 'opacity-0 scale-95',
  enterTo: 'opacity-100 scale-100',
  leave: 'transition-opacity duration-150 ease-in',
  leaveFrom: 'opacity-100 scale-100',
  leaveTo: 'opacity-0 scale-95',
};

interface CustomStyles {
  popoverContent?: string;
  arrow?: string;
}
interface PopoverProps {
  trigger: React.ReactNode;
  content: React.ReactNode;
  customStyles?: CustomStyles;
  position?: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
  closeOnClickOutside?: boolean;
  closeOnEsc?: boolean;
  animationDuration?: number;
  offset?: number;
  focusLock?: boolean;
  triggerMode?: 'click' | 'hover';
  hoverDelay?: number;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const Popover: React.FC<PopoverProps> = ({
  trigger,
  content,
  position = 'bottom',
  className = '',
  customStyles,
  animationDuration = 200,
  offset: offsetDistance = 8,
  triggerMode = 'click',
  hoverDelay = 200,
  closeOnClickOutside = true,
  closeOnEsc = true,
  isOpen: controlledOpen,
  onOpenChange,
}) => {
  const [internalOpen, setInternalOpen] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const arrowRef = useRef<HTMLDivElement>(null);
  const [mounted, setMounted] = useState(false);

  const isControlled = typeof controlledOpen !== 'undefined';
  const open = isControlled ? controlledOpen : internalOpen;

  const { x, y, strategy, placement, refs, update } = useFloating({
    placement: position as Placement,
    middleware: [
      offset(offsetDistance),
      flip(),
      shift({ padding: 8 }),
      arrow({ element: arrowRef }),
    ],
    open,
  });

  // State management
  const setOpenState = useCallback(
    (newOpen: boolean) => {
      if (!isControlled) setInternalOpen(newOpen);
      onOpenChange?.(newOpen);
    },
    [isControlled, onOpenChange]
  );

  // Click outside handler
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const referenceEl = refs.reference.current;
      const floatingEl = refs.floating.current;

      if (
        closeOnClickOutside &&
        open &&
        referenceEl &&
        floatingEl && //@ts-ignore
        !referenceEl.contains(event.target as Node) &&
        !floatingEl.contains(event.target as Node)
      ) {
        setOpenState(false);
      }
    },
    [open, closeOnClickOutside, refs.reference, refs.floating, setOpenState]
  );

  // Escape key handler
  const handleEscapeKey = useCallback(
    (event: KeyboardEvent) => {
      if (closeOnEsc && open && event.key === 'Escape') {
        setOpenState(false);
      }
    },
    [open, closeOnEsc, setOpenState]
  );

  // Event listeners
  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscapeKey);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [open, handleClickOutside, handleEscapeKey]);

  // Animation and mounting logic
  useEffect(() => {
    if (open) {
      setMounted(true);
      update();
    } else {
      const timer = setTimeout(() => setMounted(false), animationDuration);
      return () => clearTimeout(timer);
    }
  }, [open, animationDuration, update]);

  // Hover handlers
  const handleMouseEnter = useCallback(() => {
    if (triggerMode === 'hover') {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => setOpenState(true), hoverDelay);
    }
  }, [triggerMode, hoverDelay, setOpenState]);

  const handleMouseLeave = useCallback(() => {
    if (triggerMode === 'hover') {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => setOpenState(false), hoverDelay);
    }
  }, [triggerMode, hoverDelay, setOpenState]);

  // Click handler
  const handleClick = useCallback(() => {
    if (triggerMode === 'click') {
      setOpenState(!open);
    }
  }, [triggerMode, open, setOpenState]);

  // Cleanup
  useEffect(() => () => clearTimeout(timeoutRef.current), []);

  const getAnimationClasses = () => {
    const baseClasses = styles.enter;
    if (!open) {
      return `${baseClasses} ${styles.leaveFrom} ${styles.leaveTo}`;
    }
    return `${baseClasses} ${styles.enterFrom} ${styles.enterTo}`;
  };

  const triangleSize = 10;
  const getClipPath = (placement: Placement) => {
    const [side] = placement.split('-');
    switch (side) {
      case 'top':
        return 'polygon(50% 100%, 0 0, 100% 0)'; // Points down
      case 'bottom':
        return 'polygon(50% 0, 0 100%, 100% 100%)'; // Points up
      case 'left':
        return 'polygon(100% 50%, 0 0, 0 100%)'; // Points right
      case 'right':
        return 'polygon(0 50%, 100% 0, 100% 100%)'; // Points left
      default:
        return 'polygon(50% 0, 0 100%, 100% 100%)';
    }
  };

  const getArrowPlacement = (placement: Placement, triangleSize: number) => {
    const [side] = placement.split('-');
    const offset = -(triangleSize / 2);

    switch (side) {
      case 'top':
        return {
          bottom: -triangleSize,
          transform: 'translateX(-50%)',
          left: '50%',
        };
      case 'bottom':
        return {
          top: -triangleSize,
          transform: 'translateX(-50%)',
          left: '50%',
        };
      case 'left':
        return {
          right: offset,
          transform: 'translateY(-50%)',
          top: '50%',
        };
      case 'right':
        return {
          left: offset,
          transform: 'translateY(-50%)',
          top: '50%',
        };
      default:
        return {};
    }
  };

  return (
    <>
      <div
        ref={refs.setReference}
        className={`${styles.container} ${className}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div onClick={handleClick}>{trigger}</div>
      </div>
      {mounted &&
        createPortal(
          <div
            ref={refs.setFloating}
            className={`${styles.popover} ${getAnimationClasses()}`}
            style={{
              position: strategy,
              top: y ?? '',
              left: x ?? '',
              visibility: y === null ? 'hidden' : 'visible',
            }}
            role="dialog"
            aria-modal="true"
            tabIndex={-1}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className={customStyles?.popoverContent || styles.popoverContent}>{content}</div>
            <div
              ref={arrowRef}
              className={customStyles?.arrow || styles.arrow}
              style={{
                position: 'absolute',
                width: `${triangleSize}px`,
                height: `${triangleSize}px`,
                clipPath: getClipPath(placement),
                ...getArrowPlacement(placement, triangleSize),
                zIndex: 10,
              }}
            />
          </div>,
          document.body
        )}
    </>
  );
};

export default Popover;
