import client from '../client';

const Arvalis = new (class {
  async getVarietiesSoilTypes() {
    const { data } = await client.GET('/get_varieties_soil_types');
    return data;
  }
})();

export default Arvalis;
