import dayjs from 'dayjs';
import { schemas } from '../api/client';

export const getTreatments1 = (array) => {
  return array.reduce((acc, init) => {
    return {
      ...acc,
      [`${init.pd_window}id`]: init.id,
      [`${init.pd_window}id`]: init.id,
      [`${init.pd_window}pd_window`]: init.pd_window,
      [`${init.pd_window}product`]: init.product,
      [`${init.pd_window}dosage`]: init.dosage,
      [`${init.pd_window}sent`]: init.sent,
    };
  }, {});
};

export const getTreatments = (array) => {
  return array.reduce((acc, init) => {
    return { ...acc, [`${init.t_window}`]: init };
  }, {});
};

export const getTreatments2 = (spray_dates) => {
  return spray_dates.reduce((acc, init) => {
    return { ...acc, [init.spray_window]: init };
  }, {});
};
export const getTreatments3 = (spray_dates = []) => {
  let rec_spray_date = spray_dates.find((x) => {
    if (x.spray_window === 'T1') {
      return x.rec_spray_date;
    } else if (x.spray_window === 'T2') {
      return x.rec_spray_date;
    } else if (x.spray_window === 'T3') {
      return x.rec_spray_date;
    }
    return '';
  });
  return rec_spray_date?.rec_spray_date ? rec_spray_date?.rec_spray_date : null;
};

export const getTreatment = (rowData, t_window) => {
  return rowData.treatments.find((treatment) => treatment.t_window === t_window);
};

export const getTwindowValue = (rowData) => {
  const treatments = ['T3', 'T2', 'T1'];
  for (let t of treatments) {
    const treatment = rowData.treatments.find((treatment) => treatment.t_window === t);
    if (treatment) {
      return t;
    }
  }
  return null;
};

interface RiskEntry {
  date: string;
  septoriose: schemas['RiskLevel'];
  rouillebrune: schemas['RiskLevel'];
  rouillejaune: schemas['RiskLevel'];
  fusariose: schemas['RiskLevel'];
}

interface RiskResult {
  septoriose: schemas['RiskLevel'];
  rouillebrune: schemas['RiskLevel'];
  rouillejaune: schemas['RiskLevel'];
  fusariose: schemas['RiskLevel'];
  global_risk: schemas['RiskLevel'];
}

interface RiskDataPoint {
  date: string;
  value: schemas['RiskLevel'];
}

export interface RiskValuesResult {
  septoriose: RiskDataPoint[];
  rouillebrune: RiskDataPoint[];
  rouillejaune: RiskDataPoint[];
  fusariose: RiskDataPoint[];
  global_risk: RiskDataPoint[];
}

export const getRisk = (array: RiskEntry[]): RiskResult => {
  if (array.length === 0) {
    return {
      septoriose: 0,
      rouillebrune: 0,
      rouillejaune: 0,
      fusariose: 0,
      global_risk: 0,
    };
  }

  let date2 = dayjs(new Date()).format('YYYY-MM-DD');
  const properties = ['septoriose', 'rouillebrune', 'rouillejaune', 'fusariose'];
  const highestValues = {} as RiskResult;

  properties.forEach((property) => {
    const values = array.map((entry) => {
      return dayjs(entry.date).isAfter(date2) ? entry[property] : 0;
    });
    const maxValue = Math.max(...values) as schemas['RiskLevel'];
    highestValues[property] = maxValue;
  });

  highestValues.global_risk = Math.max(...Object.values(highestValues)) as schemas['RiskLevel'];
  return highestValues;
};

export const getRiskValues = (array: RiskEntry[]): RiskValuesResult => {
  let result = array.reduce((acc: RiskValuesResult, item) => {
    ['septoriose', 'rouillebrune', 'rouillejaune', 'fusariose', 'global_risk'].forEach((key) => {
      if (key === 'global_risk') {
        acc[key] = acc[key] || [];
        acc[key].push({
          date: item.date,
          value: Math.max(
            ...[item.septoriose, item.rouillebrune, item.rouillejaune, item.fusariose].filter(
              (x) => !isNaN(x)
            )
          ) as schemas['RiskLevel'],
        });
      } else {
        acc[key] = acc[key] || [];
        acc[key].push({
          date: item.date,
          value: item[key as keyof Omit<RiskEntry, 'date'>],
        });
      }
    });
    return acc;
  }, {} as RiskValuesResult);

  return result;
};

export const getCurrentValue = (array, value) => {
  return array.find((x) => x.bbch_code === value);
};

export function validateMandatoryProperties(array, mandatoryProperties) {
  return array.reduce((isValid, obj) => {
    if (!isValid) {
      return false;
    }
    for (const prop of mandatoryProperties) {
      if (obj[prop] === null || obj[prop] === undefined || obj[prop] === '') {
        return false;
      }
    }
    return true;
  }, true);
}
