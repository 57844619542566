import { useTranslation } from 'react-i18next';
import {
  UserIcon,
  PhoneIcon,
  EnvelopeIcon,
  MapPinIcon,
  BuildingOfficeIcon,
  HomeIcon,
  DevicePhoneMobileIcon,
} from '@heroicons/react/24/outline';
import { Spinner, toast } from '@tcc/labz-design-system';

import Shell from '../../Layout/shared/Shell';
import DynamicTabs from '../../Layout/shared/DynamicTabs';
import BackNextButtons from '../../Layout/checkoffer/BackNextButtons';
import FormInput from '../../Forms/FormInput';
import usePersonalDetailsForm from '../../../hooks/personalDetails/usePersonalDetailsForm';
import { useStateContext } from '../../../utils/stateContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const classNames = {
  formContainer:
    'flex flex-col w-2/3 h-full gap-4 align-center px-10 mx-auto py-auto justify-center',
  title: 'text-2xl font-bold text-primary-800 mb-4',
  subtitle: 'text-sm text-gray-600 mb-6',
  sectionTitle: 'flex items-center gap-4 mb-6',
  sectionTitleText: 'font-medium text-primary-700',
  sectionTitleLine: 'flex-grow border-t border-primary-700',
  formGrid: 'grid grid-cols-1 sm:grid-cols-2 gap-x-8 mb-4',
  checkbox: 'flex items-center',
  checkboxContainer: 'flex ml-[-15px]',
  inputIcon: 'w-5 h-5 text-gray-500 mr-2 ',
  headerIcon: 'w-5 h-5 text-primary-500',
};

const Page = () => {
  const navigate = useNavigate();
  const {
    selectedSubscriptionItem: [selectedSubscriptionItem, setSelectedSubscriptionItem],
    getAllGrowers: { refetch },
  } = useStateContext();

  const {
    fields,
    handleSubmit,
    formState: { isValid },
    isLoading,
  } = usePersonalDetailsForm(selectedSubscriptionItem);

  const { t } = useTranslation();
  const UPDATE_SUCCESS = t('PERSONAL_FORM.UPDATE_SUCCESS');
  const UPDATE_FAIL = t('ALERT.MSG11');

  useEffect(() => {
    if (!selectedSubscriptionItem?.uuid) navigate('/home');
  }, [selectedSubscriptionItem?.uuid]);

  const handleFormSubmit = async () => {
    try {
      await handleSubmit();
      await refetch();
      toast.success(UPDATE_SUCCESS);
      setTimeout(() => setSelectedSubscriptionItem(null), 2000);
    } catch (e) {
      toast.error(UPDATE_FAIL);
    }
  };

  return (
    <>
      <form className={classNames.formContainer}>
        <div className={classNames.formGrid}>
          <FormInput
            field={fields.contact_name}
            label={t('GENERIC.CONTACT')}
            icon={<UserIcon className={classNames.inputIcon} />}
          />

          <FormInput
            field={fields.telephone_no}
            label={t('GENERIC.TELEPHONE')}
            icon={<PhoneIcon className={classNames.inputIcon} />}
          />
        </div>

        <div className={classNames.formGrid}>
          <FormInput
            field={fields.mobile_no}
            label={t('PERSONAL_FORM.FIELDS.MOBILE_NO.LABEL')}
            icon={<DevicePhoneMobileIcon className={classNames.inputIcon} />}
          />

          <FormInput
            field={fields.city1}
            label={t('GROWER_FORM.FIELDS.CITY.LABEL')}
            icon={<BuildingOfficeIcon className={classNames.inputIcon} />}
          />
        </div>

        <div className={classNames.formGrid}>
          <FormInput
            field={fields.postal_code1}
            label={t('GROWER_FORM.FIELDS.POSTAL_CODE.LABEL')}
            icon={<MapPinIcon className={classNames.inputIcon} />}
          />

          <FormInput
            field={fields.email}
            label={t('REGISTRATION.EMAIL')}
            icon={<EnvelopeIcon className={classNames.inputIcon} />}
            disabled
          />
        </div>

        <div className={classNames.formGrid}>
          <FormInput
            field={fields.name}
            label={t('GENERIC.FARM')}
            icon={<HomeIcon className={classNames.inputIcon} />}
            disabled
          />
        </div>
        <div className="flex justify-center gap-4">
          <BackNextButtons nextDisabled={!isValid} onNextClick={handleFormSubmit} />
          {isLoading && <Spinner className="w-10 text-primary-500" />}
        </div>
      </form>
    </>
  );
};

const Tabs = () => {
  const { t } = useTranslation('');
  const PERSONAL_DETAILS = t('PERSONAL.DETAILS');
  const tabs = [
    {
      id: 'personal_details',
      name: PERSONAL_DETAILS,
      children: <Page />,
    },
  ];

  return <DynamicTabs tabs={tabs} />;
};

const PersonalDetails = () => {
  return (
    <Shell footer={false} loading={false}>
      <Tabs />
    </Shell>
  );
};

export default PersonalDetails;
