import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { useStateContext } from '../../utils/stateContext';
import * as Yup from 'yup';
import CustomModal from '../CustomModal';
import { toast, Button, Spinner } from '@tcc/labz-design-system';
import useFormFactory from '../../hooks/useFormFactory';
import FormDateInput from '../Forms/FormDateInput';
import FormInput from '../Forms/FormInput';
import { useEffect } from 'react';
import { AgronomyField } from '../../hooks/useAgronomySummary';
import { useDeleteTreatments } from '../../hooks/queries/agronomy/useDeleteTreatment';
import { useUpdateTreatment } from '../../hooks/queries/agronomy/useUpdateTreatment';

interface FormValues {
  id?: number;
  field_id: number;
  t_window: string;
  treatment_date: string;
  dose_rate: string;
  product: string;
}

interface Treatment extends FormValues {
  id?: number;
}

const styles = {
  modalContainer: '',
  form: 'flex flex-col gap-5 w-full px-4',
  header: 'flex justify-between items-center',
  headerTitle: 'font-semibold text-sm mt-1',
  inputContainer: 'w-full',
  errorText: 'text-red-500 text-xs',
  tWindowContainer: 'flex items-center gap-4 w-full justify-center',
  fieldError: 'text-red-600 text-sm',
  tWomdowBtnInactive: 'bg-slate-400 border-slate-400',
  buttonContainer: 'flex items-center justify-evenly mb-2 gap-2',
};

interface TreatmentFormModalProps {
  selectedTreatment: Treatment;
  selectedField: AgronomyField;
  onClose: () => void;
  isOpen: boolean;
}

const TreatmentFormModal = ({
  selectedField,
  onClose,
  isOpen,
  selectedTreatment,
}: TreatmentFormModalProps) => {
  const { t } = useTranslation();
  const {
    getAllGrowers: { refetch },
  } = useStateContext();

  const { mutateAsync: updateTreatment, isPending: isUpdatingTreatment } = useUpdateTreatment();
  const { mutateAsync: deleteTreatment, isPending: isDeletingTreatment } = useDeleteTreatments();

  const validationSchema: Yup.ObjectSchema<FormValues> = Yup.object({
    id: Yup.number().optional(),
    field_id: Yup.number().required('field_id'),
    t_window: Yup.string()
      .oneOf(['T1', 'T2', 'T3'])
      .required(t('AGRONOMY.TREATMENT_FORM.FIELDS.T_WINDOW.REQUIRED')),
    treatment_date: Yup.string().required(
      t('AGRONOMY.TREATMENT_FORM.FIELDS.TREATMENT_DATE.REQUIRED')
    ),
    dose_rate: Yup.string().required(t('AGRONOMY.TREATMENT_FORM.FIELDS.DOSE_RATE.REQUIRED')),
    product: Yup.string().required(t('AGRONOMY.TREATMENT_FORM.FIELDS.PRODUCT.REQUIRED')),
  }).required();

  const defaultValues: FormValues = {
    field_id: selectedField?.field_id,
    ...(selectedTreatment?.id ? { id: selectedTreatment?.id } : {}),
    treatment_date: selectedTreatment?.treatment_date,
    dose_rate: selectedTreatment?.dose_rate,
    product: selectedTreatment?.product,
    t_window: selectedTreatment?.t_window,
  };

  const onSubmit = async (data: FormValues) => {
    try {
      await updateTreatment(data);
      toast.success(t('AGRONOMY.BBCH.TREATMENT_DETAILS_ADDED'));
      handleSuccess();
    } catch (e) {}
  };

  const handleDelete = async () => {
    try {
      await deleteTreatment(selectedTreatment as Treatment);
      toast.success(t('AGRONOMY.BBCH.TREATMENT_DETAILS_DELETED'));
      handleSuccess();
    } catch (e) {
      toast.error(t('AGRONOMY.PRODUCT_DETAILS_NOT_FOUND'));
    }
  };

  const handleSuccess = () => {
    refetch();
    handleClose();
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleTwindowChange = (newValue: string) => {
    fields.t_window.setValue(newValue);
    fields.t_window.trigger();
  };

  const formFactoryProps = {
    defaultValues,
    validationSchema,
    onSubmit,
  };

  const {
    fields,
    handleSubmit,
    values,
    formState: { isValid, errors },
    reset,
  } = useFormFactory(formFactoryProps);

  useEffect(() => {
    fields.field_id.setValue(selectedField?.field_id);
    if (selectedTreatment?.id) {
      fields.id.setValue(selectedTreatment.id);
      fields.treatment_date.setValue(
        selectedTreatment.treatment_date ? new Date(selectedTreatment.treatment_date) : new Date()
      );
      fields.dose_rate.setValue(selectedTreatment.dose_rate);
      fields.product.setValue(selectedTreatment.product);
      handleTwindowChange(selectedTreatment.t_window);
    }
    fields.field_id.trigger();
  }, [selectedTreatment?.id, selectedField?.field_id]);

  useEffect(() => {}, [selectedField?.field_id]);

  const isLoading = isUpdatingTreatment || isDeletingTreatment;

  return (
    <CustomModal
      open={isOpen}
      title={t('AGRONOMY.ACT_DATE')}
      handleClose={() => handleClose()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.modalContainer}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.inputContainer}>
            <FormDateInput field={fields.treatment_date} label={t('AGRONOMY.PICK_DATE')} />
          </div>

          <div className={styles.inputContainer}>
            <FormInput field={fields.product} label={t('AGRONOMY.PRODUCT')} />
          </div>

          <div className={styles.inputContainer}>
            <FormInput field={fields.dose_rate} label={t('AGRONOMY.DOSAGE')} />
          </div>

          <div>
            <span>{t('ADD_PRODUCT.T_WINDOW')}</span>
            <div className={styles.tWindowContainer}>
              <Button
                type="button"
                value={values.t_window}
                onClick={() => handleTwindowChange('T1')}
                className={values.t_window !== 'T1' ? styles.tWomdowBtnInactive : ''}
              >
                T1
              </Button>
              <Button
                type="button"
                value={values.t_window}
                onClick={() => handleTwindowChange('T2')}
                className={values.t_window !== 'T2' ? styles.tWomdowBtnInactive : ''}
              >
                T2
              </Button>
              <Button
                type="button"
                value={values.t_window}
                onClick={() => {
                  handleTwindowChange('T3');
                  fields.t_window.trigger();
                }}
                className={values.t_window !== 'T3' ? styles.tWomdowBtnInactive : ''}
              >
                T3
              </Button>
              {isLoading && <Spinner className="w-5 text-primary-500" />}
            </div>
            <span className={styles.fieldError}>{errors.t_window?.message}</span>
          </div>

          <div className={styles.buttonContainer}>
            <Button
              type="button"
              onClick={handleDelete}
              className="w-1/2"
              variant="outlined"
              disabled={!values.id || isLoading}
            >
              <Typography variant="body2">{t('GENERIC.DELETE')}</Typography>
            </Button>
            <Button type="submit" className="w-1/2" disabled={!isValid || isLoading}>
              {t('GENERIC.SAVE')}
            </Button>
          </div>
        </form>
      </div>
    </CustomModal>
  );
};
export default TreatmentFormModal;
