import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import GrowerAPI from '../../../api/grower';

export const useDeleteTreatments = () => {
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['deleteTreatment'],
    mutationFn: async (treatment: any) => {
      const response = await GrowerAPI.deleteTreatments(treatment);

      if (response.status !== 200) throw new Error(t('ALERT.MSG11'));
      return response;
    },
  });
};
