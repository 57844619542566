import { Typography, Select, MenuItem } from '@mui/material';
import React from 'react';

const TWindowFilter = ({ onChange, value = 'All' }) => {
  return (
    <Select sx={{ display: 'flex', flex: 1, height: 30 }} value={value} onChange={onChange}>
      <MenuItem value="All">
        <Typography sx={{ fontSize: '0.7em', textAlign: 'center' }}>All</Typography>
      </MenuItem>
      <MenuItem value="T1">
        <Typography sx={{ fontSize: '0.7em', textAlign: 'center' }}>T1</Typography>
      </MenuItem>
      <MenuItem value="T2">
        <Typography sx={{ fontSize: '0.7em', textAlign: 'center' }}>T2</Typography>
      </MenuItem>
      <MenuItem value="T3">
        <Typography sx={{ fontSize: '0.7em', textAlign: 'center' }}>T3</Typography>
      </MenuItem>
    </Select>
  );
};

export default TWindowFilter;
