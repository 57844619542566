import { useMemo, useState } from 'react';
import useListToggle from '../useListToggle';
import GrowerAPI from '../../api/grower';
import useGetOffers from '../queries/features/offer/useGetOffers';
import { schemas } from '../../api/client';
import { RefetchOptions, QueryObserverResult } from '@tanstack/react-query';

type Offer = schemas['OfferOut'];
type FieldOut = schemas['FieldOut'];
type FieldUpdateIn = schemas['FieldUpdateIn'];

interface SelectedFields extends FieldOut {
  soil_type_id?: number;
  soil_code?: string;
}

type DeliveryDetails = {
  address1?: string;
  order_contact_name: string;
  order_mobile_no: string;
  order_del_address: string;
  order_del_postal_code: string;
  order_del_city: string;
  order_add_info: string;
};

export interface UseOffersReturn {
  offers: Offer[] | null;
  error: any;
  loading: boolean;
  selectedOffer: Offer | undefined;
  setSelectedOffer: React.Dispatch<React.SetStateAction<Offer | undefined>>;
  toggleSelectField: (uuid: string) => void;
  isFieldSelected: (uuid: string) => boolean;
  updateFieldviewData: any;
  selectedFields: SelectedFields[];
  setSelectedFields: React.Dispatch<React.SetStateAction<SelectedFields[]>>;
  totalPrice: string;
  totalArea: number;
  updatingFieldviewData: boolean;
  fetchOffers: (options?: RefetchOptions) => Promise<QueryObserverResult<Offer[], Error>>;
  fieldsImportedFromFieldView: [FieldOut[], React.Dispatch<React.SetStateAction<FieldOut[]>>];
  selectedDeliveryDetails: DeliveryDetails;
  setSelectedDeliveryDetails: React.Dispatch<React.SetStateAction<DeliveryDetails>>;
}

const useOffers = ({ distributorId }: { distributorId: number }): UseOffersReturn => {
  const [selectedOffer, setSelectedOffer] = useState<Offer | undefined>(undefined);
  const [selectedFields, toggleSelectField, isFieldSelected, setSelectedFields] =
    useListToggle<any>([], 'uuid');
  const fieldsImportedFromFieldView = useState<any>();
  const [updatingFieldviewData, setUpdatingFieldviewData] = useState<boolean>(false);
  const [selectedDeliveryDetails, setSelectedDeliveryDetails] = useState({
    order_contact_name: '',
    order_mobile_no: '',
    order_del_address: '',
    order_del_city: '',
    order_del_postal_code: '',
    order_add_info: '',
  });

  const { data: offers, error, isLoading: loading, refetch } = useGetOffers(distributorId);

  const totalArea = useMemo(
    () => selectedFields.reduce((sum, field) => sum + (field.area || 0), 0),
    [selectedFields]
  );

  const totalPrice = useMemo(() => {
    if (!selectedOffer) return '0.00';

    const price = totalArea * (selectedOffer.price || 0);
    return price.toFixed(2);
  }, [selectedFields, selectedOffer, totalArea]);

  const updateFieldviewData = async ({
    fields,
    onSuccess = () => {},
    onFail = () => {},
  }: {
    fields: FieldUpdateIn[];
    onSuccess;
    onFail;
  }) => {
    try {
      setUpdatingFieldviewData(true);
      await GrowerAPI.updateFieldviewData(fields);
      onSuccess();
    } catch (error) {
      onFail();
    } finally {
      setUpdatingFieldviewData(false);
    }
  };

  return {
    offers,
    error,
    loading,
    selectedOffer,
    setSelectedOffer,
    toggleSelectField,
    isFieldSelected,
    updateFieldviewData,
    selectedFields,
    setSelectedFields,
    totalPrice,
    totalArea,
    updatingFieldviewData,
    fetchOffers: refetch,
    fieldsImportedFromFieldView,
    selectedDeliveryDetails,
    setSelectedDeliveryDetails,
  };
};

export default useOffers;
