import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../utils/stateContext';
// import Drawer from '../Table/Drawer';
import Shell from '../Layout/shared/Shell';
import Agronomy from './agronomy/Agronomy';
import Subscription from './subscription/Subscription';
import { useParams } from 'react-router-dom';

const PageContent = () => {
  const { view = 'subscription' } = useParams();
  const { t } = useTranslation();

  const { userDetails } = useStateContext();

  if (userDetails?.role === 100) return <div>{t('ALERT.MSG19')}</div>;

  const routeComponents = {
    subscription: <Subscription />,
    agronomy: <Agronomy />,
  };

  return <>{routeComponents[view]}</>;
};

const Admin = () => {
  const {
    getAllGrowers: { isLoading, isRefetching },
  } = useStateContext();

  return (
    <Shell footer={false} loading={isLoading || isRefetching}>
      <PageContent />
    </Shell>
  );
};

export default Admin;
