import React, { ReactNode } from 'react';
//import { Input } from '@tcc/labz-design-system';
import { Input } from './Input';

const classNames = {
  inputContainer: 'flex flex-col w-full relative',
  inputLabel: 'flex font-helvetica text-gray-800 items-center mb-1',
  fieldError: 'text-red-600 text-sm absolute right-0 bottom-[-22px]',
  input: 'w-full',
  greyedOut: 'opacity-50',
};

type FormInputProps = {
  label?: string;
  type?: string;
  required?: boolean;
  onFocus?: () => void;
  field: any;
  disabled?: boolean;
  TrailingIcon?: ReactNode;
  trim?: boolean;
  icon?: React.ReactNode;
};

const FormInput: React.FC<FormInputProps> = ({
  field,
  label,
  type = 'text',
  required = true,
  disabled = false,
  TrailingIcon = <></>,
  onFocus = () => {},
  trim = false,
  icon = <></>,
}) => {
  const { name, trigger, setValue, error, value } = field;

  const handleChange = ({ currentTarget: { value } }) => {
    setValue(value);
    trigger(name);
  };

  const handleBlur = ({ currentTarget: { value } }) => {
    if (value && trim) setValue(value?.trim());
    trigger(name);
  };

  return (
    <div className={`${classNames.inputContainer} ${disabled ? classNames.greyedOut : ''}`}>
      <span className={classNames.inputLabel}>
        {icon}
        {label}
        {required && <>*</>}
      </span>
      <Input
        value={value}
        type={type}
        placeholder=""
        className={classNames.input}
        onFocus={onFocus}
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        disabled={disabled}
        TrailingIcon={TrailingIcon}
      />
      <span className={classNames.fieldError}>{error?.message}</span>
    </div>
  );
};

export default FormInput;
