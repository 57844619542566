import Card from '../../../Layout/Card';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../../utils/stateContext';
import { TruckIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { set } from 'react-hook-form';

const DeliveryDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    selectedSubscriptionItem: [
      {
        order: {
          order_contact_name,
          order_mobile_no,
          order_del_address,
          order_del_city,
          order_del_postal_code,
          order_add_info,
        },
      },
    ],
    offers: { setSelectedDeliveryDetails },
  } = useStateContext();

  const handleEditClick = () => {
    setSelectedDeliveryDetails({
      order_contact_name,
      order_mobile_no,
      order_del_address,
      order_del_city,
      order_del_postal_code,
      order_add_info,
    });
    navigate('/home/deliverydetails');
  };

  return (
    <Card
      title={`${t('GENERIC.DELIVERY')} ${t('GENERIC.DETAILS')}`}
      icon={<TruckIcon className="w-5 h-5 text-white" />}
      iconPosition="start"
      gap="gap-2"
      margin="m-0"
      editable
      onEditClick={handleEditClick}
    >
      <div className="flex justify-between w-full">
        <span className="font-light">{t('GENERIC.CONTACT')}</span>
        <span>{order_contact_name || '--'}</span>
      </div>
      <div className="flex justify-between w-full">
        <span className="font-light">{t('GENERIC.MOBILE_NO')}</span>
        <span>{order_mobile_no || '--'}</span>
      </div>
      <div className="flex justify-between w-full">
        <span className="font-light">{t('GENERIC.DEL_ADDRESS')}</span>
        <span>{order_del_address || '--'}</span>
      </div>
    </Card>
  );
};

export default DeliveryDetails;
