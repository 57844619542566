import Card from '../../../Layout/Card';
import { useTranslation } from 'react-i18next';
import { useStateContext } from '../../../../utils/stateContext';
import { HomeIcon } from '@heroicons/react/24/solid';

const FarmDetails = () => {
  const { t } = useTranslation();
  const {
    selectedSubscriptionItem: [{ name = '--', address1 = '--' }],
  } = useStateContext();

  return (
    <Card
      title={t('FARM.DETAILS')}
      gap="gap-2"
      margin="m-0"
      iconPosition="start"
      icon={<HomeIcon className="w-5 h-5 text-white" />}
    >
      <div className="flex justify-between w-full">
        <span className="font-light">{t('GENERIC.FARM')}</span>
        <span>{name} </span>
      </div>
      <div className="flex justify-between w-full">
        <span className="font-light">{t('GENERIC.ADDRESS')}</span>
        <span>{address1}</span>
      </div>
    </Card>
  );
};

export default FarmDetails;
