import { useMemo, useState } from 'react';

import {
  getCurrentValue,
  getRiskValues,
  getRisk,
  getTreatments3,
  getTreatment,
  getTwindowValue,
  RiskValuesResult,
} from '../utils/helpers';
import { schemas } from '../api/client';
import { components } from '../api/client/schema';
import { getFormatted } from '../components/Forms/FormDateInput';
import useLocalStorage from './localStorage/useLocalStorage';

export type Treatment =
  components['schemas']['predima_disti_tool__src__core__data_access__db__treatments__schemas__response__TreatmentOut'];

export interface AgronomyField {
  field_id: number;
  distributor: string;
  created_user_name: string;
  contact_name: string;
  farm_name: string;
  email: string;
  global_risk: schemas['RiskLevel'];
  field_name: string;
  bbch: schemas['GrowthStageSummaryOut'];
  observation_date: string | null;
  sowing_date: string | null;
  septoriose: schemas['RiskLevel'];
  rouillebrune: schemas['RiskLevel'];
  rouillejaune: schemas['RiskLevel'];
  fusariose: schemas['RiskLevel'];
  treatment_window: string;
  alert_date?: string;
  rec_spray_date1?: string | null;
  treatment: Treatment | null;
  legend: RiskValuesResult;
}

export const useAgronomySummary = (growerList: schemas['GrowerOut'][] = []) => {
  const [locale] = useLocalStorage('locale', 'fr');
  const [lastUpdated, setLastUpdated] = useState(new Date());

  const growerFields = useMemo(() => {
    const result: AgronomyField[] = [];
    if (growerList.length > 0) {
      growerList.forEach((grower) => {
        if (grower.order?.order_fields?.length) {
          grower.order.order_fields.forEach((field) => {
            const risk = getRisk(field.risk);
            const treatment_window = getTwindowValue(field);
            const treatment = getTreatment(field, treatment_window);
            const rec_spray_date1 = getTreatments3(field.spray_dates);

            result.push({
              field_id: field.id,
              distributor: grower?.created_user?.distributor?.name || '',
              created_user_name: grower?.created_user?.name || '',
              contact_name: grower.contact_name,
              farm_name: grower.name,
              global_risk: risk.global_risk,
              field_name: field.field_name,
              email: grower.email,
              bbch: getCurrentValue(field.growth_stages, field.current_bbch_stage),
              observation_date: getFormatted(field.observation_date, locale),
              sowing_date: getFormatted(field.sowing_date, locale),
              septoriose: risk.septoriose,
              rouillebrune: risk.rouillebrune,
              rouillejaune: risk.rouillejaune,
              fusariose: risk.fusariose,
              treatment_window,
              legend: getRiskValues(field.risk),
              rec_spray_date1: getFormatted(rec_spray_date1, locale),
              treatment,
            });
          });
        }
      });
      setLastUpdated(new Date());
    }
    return result;
  }, [growerList]);

  return { growerFields, lastUpdated };
};
