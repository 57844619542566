import { useMemo } from 'react';

interface Column {
  name: string;
  aggregate?: boolean;
  valueGetter?: (row: any) => any;
}

const useAggregations = (dataList: any[], columns: Column[]) => {
  const aggregations = useMemo(() => {
    const result: { [name: string]: number } = {};

    columns.forEach((column) => {
      if (column.aggregate) {
        const name = column.name;
        const total = dataList.reduce((sum, item) => {
          // Use valueGetter if available, otherwise use direct property access
          const rawValue = column.valueGetter ? column.valueGetter(item) : item[name];

          // Convert to Number and check if it's not NaN
          const value = Number(rawValue);
          return sum + (!isNaN(value) ? value : 0);
        }, 0);

        result[name] = total;
      }
    });

    return result;
  }, [dataList, columns]);

  return aggregations;
};

export default useAggregations;
