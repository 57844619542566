// useLocalStorage.ts
import { useState, useEffect } from 'react';

// Create a custom event for storage updates
const createStorageEvent = (key: string, value: any) =>
  new CustomEvent('local-storage', { detail: { key, value } });

function useLocalStorage(key: string, initialValue: any) {
  // Get initial value
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage.getItem(key);
      if (!item || item === 'undefined' || item === 'null') {
        return initialValue;
      }
      return JSON.parse(item);
    } catch (error) {
      console.error('Error reading localStorage key "' + key + '": ', error);
      return initialValue;
    }
  });

  // Listen to storage changes
  useEffect(() => {
    const handleStorageChange = (e: CustomEvent) => {
      if (e.detail.key === key) {
        setStoredValue(e.detail.value);
      }
    };

    // Listen for our custom event
    window.addEventListener('local-storage' as any, handleStorageChange);

    // Cleanup
    return () => {
      window.removeEventListener('local-storage' as any, handleStorageChange);
    };
  }, [key]);

  const setValue = (value: any) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);

      if (valueToStore === undefined) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(valueToStore));
      }

      // Dispatch custom event to notify other components
      window.dispatchEvent(createStorageEvent(key, valueToStore));
    } catch (error) {
      console.error('Error setting localStorage key "' + key + '": ', error);
    }
  };

  return [storedValue, setValue];
}

export default useLocalStorage;
