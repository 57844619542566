import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { toast } from '@tcc/labz-design-system';
import { useTranslation } from 'react-i18next';
import { schemas } from '../../../../api/client';
import { getDistributorOffers } from '../../../../api/offer';

type Offer = schemas['OfferOut'];

const useGetOffers = (distributorId: number) => {
  const { t } = useTranslation();
  return useQuery<Offer[], Error>({
    queryKey: ['distributorOffers'],
    queryFn: async () => getDistributorOffers(distributorId),
    onError: () => {
      toast.error(t('ALERT.MSG11'));
    },
    enabled: !!distributorId,
  } as UseQueryOptions<Offer[], Error>);
};

export default useGetOffers;
