import { useMemo } from 'react';
import EditableCell from '../../Table/EditableCell';
import { useTranslation } from 'react-i18next';
import { getFormatted } from '../../Forms/FormDateInput';
import useLocalStorage from '../../../hooks/localStorage/useLocalStorage';

const useAgronomySummaryColumns = (handleEditBBCH, handleEditActualSprayDate) => {
  const [locale] = useLocalStorage('locale', 'fr');
  const { t } = useTranslation();
  const columnWidth = `${100 / 18}%`;

  return useMemo(() => {
    return [
      {
        name: 'distributor',
        label: t('SUBSCRIPTION.DISTRIBUTOR'),
        search: true,
        width: columnWidth,
      },
      {
        name: 'created_user_name',
        label: t('SUBSCRIPTION.SALES_REP_NAME'),
        search: true,
        width: columnWidth,
      },
      {
        name: 'contact_name',
        label: t('SUBSCRIPTION.GROWER'),
        search: true,
        width: columnWidth,
      },
      {
        name: 'farm_name',
        label: t('SUBSCRIPTION.FARM'),
        search: true,
        width: columnWidth,
      },
      { name: 'global_risk', label: t('AGRONOMY.GLOBAL_RISK'), risk: true, width: columnWidth },
      {
        name: 'field_name',
        label: t('GENERIC.FIELD'),
        search: true,
        width: columnWidth,
      },
      {
        name: 'bbch',
        label: 'BBCH',
        search: true,
        renderer: (rowData) => {
          return (
            <span className="flex justify-center min-w-[80px]">
              {rowData.bbch?.bbch_code ? rowData.bbch.bbch_code.replace('Z', '') : '--'}
            </span>
          );
        },
      },
      {
        name: 'observation_date',
        label: 'BBCH 30',
        date: true,
        width: columnWidth,
        renderer: (rowData) => (
          <EditableCell
            data={rowData}
            value={rowData.observation_date}
            onClick={() => handleEditBBCH(rowData)}
          />
        ),
      },
      {
        name: 'sowing_date',
        label: t('FIELD.SOWING_DATE'),
        date: true,
        width: columnWidth,
        className: 'text-nowrap',
      },
      {
        name: 'septoriose',
        label: t('AGRONOMY.SEPTORIA'),
        risk: true,
        width: columnWidth,
      },
      {
        name: 'rouillebrune',
        label: t('AGRONOMY.BROWN_RUST'),
        risk: true,
        width: columnWidth,
      },
      {
        name: 'rouillejaune',
        label: t('AGRONOMY.YELLOW_RUST'),
        risk: true,
        width: columnWidth,
      },
      {
        name: 'fusariose',
        label: t('AGRONOMY.FUSARIUM'),
        risk: true,
        width: columnWidth,
      },
      {
        name: 'treatment_window',
        label: t('ADD_PRODUCT.T_WINDOW'),
        treatmentFiter: true,
        width: columnWidth,
      },
      {
        name: 'rec_spray_date1',
        label: t('AGRONOMY.REC_DATE'),
        date: true,
        width: columnWidth,
      },
      {
        name: 'treatment',
        label: t('AGRONOMY.ACT_DATE'),
        width: columnWidth,
        date: true,
        valueGetter: (rowData) => getFormatted(rowData.treatment?.treatment_date, locale),
        renderer: (rowData) => (
          <EditableCell
            data={rowData}
            value={getFormatted(rowData.treatment?.treatment_date, locale)}
            onClick={() => handleEditActualSprayDate(rowData)}
          />
        ),
      },
    ];
  }, [t, columnWidth, handleEditActualSprayDate, handleEditBBCH]);
};

export default useAgronomySummaryColumns;
