import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import useFormFactory from '../useFormFactory';

type DefaultValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  language: string;
};

const useRegistrationForm = (onSubmit) => {
  const { t } = useTranslation();

  const languageOptions = [
    { id: 'en', label: t('PROFILE.ENGLISH'), value: 'en' },
    { id: 'fr', label: t('PROFILE.FRENCH'), value: 'fr' },
    { id: 'de', label: t('PROFILE.GERMAN'), value: 'de' },
  ];

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('REGISTER.ERR_FIRST')),
    lastName: Yup.string().required(t('REGISTER.ERR_LAST')),
    email: Yup.string().required(t('REGISTER.ERR_EMAIL')).email(t('REGISTER.ERR_EMAIL_INVALID')),
    password: Yup.string()
      .required(t('PASSWORD.REQUIRED'))
      .min(8, t('PASSWORD.MIN_LENGTH'))
      .matches(/[a-z]/, t('PASSWORD.LOWERCASE'))
      .matches(/[A-Z]/, t('PASSWORD.UPPERCASE'))
      .matches(/[0-9]/, t('PASSWORD.NUMBER'))
      .matches(/[!@#$%^&*(),.?":{}|<>]/, t('PASSWORD.SPECIAL_CHARACTER')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), ''], t('REGISTER.ERR_PASS_MATCH'))
      .required(t('REGISTER.ERR_CONFIRM_PASS')),
    language: Yup.string().required(t('REGISTER.ERR_LANG')),
  });

  const defaultValues: DefaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    language: 'en',
  };

  const formFactoryProps = {
    defaultValues,
    validationSchema,
    onSubmit,
  };

  return {
    ...useFormFactory(formFactoryProps),
    languageOptions,
  };
};

export default useRegistrationForm;
