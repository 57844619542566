import { useStateContext } from '../../utils/stateContext';
import { t } from 'i18next';

const classNames = {
  container: 'flex flex-col bg-background-1 h-full rounded-bl-md',
  header: 'text-center text-white bg-primary-500 py-4',
  content: 'flex flex-col h-full gap-2',
  row: 'px-4 flex justify-between items-center',
  headerDetails: 'bg-primary-500 py-4 w-full border-t border-gray-300',
  title: 'text-sm font-medium text-white',
  value: 'text-sm',
  totalArea: 'text-sm font-bold mt-4',
  acceptedText: 'text-xs py-3 px-4',
  priceContainer: 'flex flex-col items-center gap-1 place-self-end pr-4',
  totalPrice: 'flex place-self-end text-xl text-[#0091DF] font-bold',
  pricePerHa: 'text-xs',
  euroSign: 'text-sm flex place-self-end mr-2 mb-1 text-text-3',
};

const OrderSummary = () => {
  const {
    offers: { selectedFields, selectedOffer, totalPrice, totalArea },
  } = useStateContext();

  const pricePerHa = t('CHECKOFFER.PRICE').replace('-price-', selectedOffer?.price.toString());

  return (
    <div className={classNames.container}>
      <div className={classNames.header}>{t('DELIVERY.ORDER_SUMMARY')}</div>
      <div className={classNames.content}>
        <div className={`${classNames.headerDetails} ${classNames.row}`}>
          <span className={classNames.title}>{t('DELIVERY.SELECTED_FIELDS')}</span>
          <span className={classNames.title}>{t('GENERIC.SIZE')}</span>
        </div>
        {selectedFields.map((field) => (
          <div key={field.field_name} className={classNames.row}>
            <span className={classNames.value}>{field.field_name}</span>
            <span className={classNames.value}>{field.area}</span>
          </div>
        ))}
        <div className={classNames.row}>
          <span className={classNames.totalArea}>{t('DELIVERY.TOTAL_AREA')}</span>
          <span className={classNames.totalArea}>{totalArea.toFixed(2)}</span>
        </div>
        <div className={classNames.acceptedText}>{t('DELIVERY.ACCEPTED')}</div>
        <div className={classNames.priceContainer}>
          <div className={classNames.totalPrice}>
            <span className={classNames.euroSign}>€</span> {totalPrice}
          </div>
          <div className={classNames.pricePerHa}>{pricePerHa}</div>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
