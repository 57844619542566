import { Typography } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import React from 'react';
import { useTranslation } from 'react-i18next';
interface Comment {
  id?: string;
  text?: string;
}
interface ComplianceData {
  compliance?: boolean;
  field_id?: string;
  field_name?: string;
  comments?: Comment[];
}
interface ComplianceCellProps {
  data: ComplianceData;
  onClick?: () => void;
}

const ComplianceCell: React.FC<ComplianceCellProps> = ({
  data: { compliance = false, field_id, comments = [] },
  onClick = () => {},
}) => {
  const { t } = useTranslation();
  const YES = t('GENERIC.YES');
  const NO = t('GENERIC.NO');

  return (
    <>
      <Typography sx={{ display: 'inline', fontSize: 13 }}>{compliance ? YES : NO}</Typography>
      <ChatBubbleOutlineIcon
        sx={{
          color: !field_id ? '#d2d2d2' : comments.length > 0 ? '#FF3162' : '#0B617F',
          float: 'right',
          cursor: !field_id ? 'not-allowed' : 'pointer',
        }}
        onClick={onClick}
      />
    </>
  );
};

export default ComplianceCell;
