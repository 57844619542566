import { Box } from '@mui/material';

const colors = {
  40: '#0091DF',
  30: '#FF0000',
  20: '#FFC400',
  10: '#66B512',
  0: '#BABABA',
};

const Dot = ({ color, size }) => (
  <Box
    component="span"
    sx={{
      height: size,
      width: size,
      backgroundColor: colors[`${color}`],
      borderRadius: '50%',
      display: 'inline-block',
    }}
  ></Box>
);

export default Dot;
