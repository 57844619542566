import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from '@tcc/labz-design-system';
import LoginAPI from '../../api/login';
import UserAPI from '../../api/user';
import useLoginForm from './useLoginForm';
import useLocalStorage from '../localStorage/useLocalStorage';
import { LoginResponse } from '../../api/login/types';
import { useEffect, useState } from 'react';

const defaultLocale = 'en';

export interface UseLoginReturn {
  handleLogin: ({ email, password }: { email: string; password: string }) => Promise<void>;
  form: ReturnType<typeof useLoginForm>;
  user: LoginResponse | null;
  userData: LoginResponse | null;
  userDetails: any;
  login: (user: LoginResponse, userDetails: getUserByEmailResponse) => void;
  logout: () => void;
}

export interface getUserByEmailResponse {
  [key: string]: any;
}

const useLogin = (): UseLoginReturn => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [token, setToken] = useLocalStorage('token', null);
  const [userDetails, setUserDetails] = useLocalStorage('userDetails', null);
  const [locale, setLocale] = useLocalStorage('locale', defaultLocale);
  const [user, setUser] = useState<LoginResponse | null>(null);
  const [gigyaUserData, setGigyaUserData] = useLocalStorage('gigyaUser', null);

  useEffect(() => {
    if (gigyaUserData) {
      setUser(gigyaUserData);
    }
  }, [gigyaUserData]);

  useEffect(() => {
    if (userDetails) {
      setUserDetails(userDetails);
    }
  }, [userDetails]);

  const login = (user: LoginResponse, userDetails: getUserByEmailResponse) => {
    setUser(user);
    setGigyaUserData(user);
    setUserDetails(userDetails);
  };

  const logout = () => {
    setUser(null);
    localStorage.clear();
    navigate('/');
  };

  const handleLogin = async ({ email, password }: { email: string; password: string }) => {
    // Clear all stored values
    setToken(null);
    setLocale(defaultLocale);

    const loginResponse = await LoginAPI.loginUser({
      loginID: email,
      password,
    });

    if (loginResponse.statusCode === 409 || loginResponse.statusCode !== 200) {
      toast.error(t('ALERT.MSG13'));
      return;
    }

    const tokenResponse = await LoginAPI.getJWT(loginResponse.UID);
    if (tokenResponse.statusCode !== 200) {
      toast.error(t('ALERT.MSG11'));
      return;
    }

    const id_token = tokenResponse.id_token;
    setToken(id_token);

    try {
      const predimaUserResponse: getUserByEmailResponse = await UserAPI.getUserByEmail({ email });
      const accountResponse = await LoginAPI.accountSearch(email);
      const accountLocale = accountResponse.results[0]?.profile?.locale || defaultLocale;
      setLocale(accountLocale);

      login(loginResponse, predimaUserResponse.data);
      await i18n.changeLanguage(accountLocale);

      navigate('/home');
    } catch (error) {
      toast.error(t('ALERT.MSG11'));
      console.error(error);
    }
  };

  const form = useLoginForm(handleLogin);

  return {
    handleLogin,
    form,
    user,
    userData: gigyaUserData,
    userDetails,
    login,
    logout,
  };
};

export default useLogin;
