import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { memo, useMemo } from 'react';
import useFilteredList from '../hooks/useFilteredList';
import useSortedList from '../hooks/useSortedList';
import CustomTablePagination from '../utils/CustomTablePagination';
import { getCellValue } from '../utils/commonFunctions';
import FilterBar from './Table/FilterBar';
import HeaderRow from './Table/HeaderRow';
import EmptyCellIndicator from './Table/EmptyCellIndicator';
import usePagination from '../hooks/usePagination';
import useAggregations from '../hooks/useAggregations';
import AggregationsRow from './Table/AggregationsRow';
import { NoDataAvailable } from './Table/NoDataAvailable';
import { Checkbox } from '@tcc/labz-design-system';
import ComplianceCell from './Table/ComplianceCell';
import RiskCell from './Table/RiskCell';
export interface PredimaTableColumn<T = any> {
  [key: string]: any;
  name: string;
  label?: string;
  width?: string;
  search?: boolean;
  aggregate?: boolean;
  formatNumber?: boolean;
  valueGetter?: (row: T) => any;
  renderer?: (row: T) => React.ReactNode;
  onClick?: (row: T) => void;
}

export type RowData = Record<string, any>;
export interface PredimaTableProps<T extends RowData> {
  data: T[];
  columns: PredimaTableColumn[];
  showFilters: boolean;
  loading?: boolean;
  onSelect?: (row: T) => void;
  onSelectAll?: () => void;
  selectable?: boolean;
  width?: string;
  allSelected?: boolean;
  selectedRows?: T[];
  rowKey?: keyof T;
}

const PredimaTable = <T extends RowData>({
  data = [],
  columns,
  showFilters = false,
  loading = false,
  onSelect = () => {},
  onSelectAll = () => {},
  selectable = true,
  width = '100%',
  allSelected = false,
  selectedRows = [],
  rowKey = 'id',
}: PredimaTableProps<T>) => {
  const { filteredData, ...filterProps } = useFilteredList(data, columns);
  const { sortedData, ...sortingProps } = useSortedList(filteredData, columns);
  const { paginatedData, ...paginationProps } = usePagination(sortedData);

  const aggregations = useAggregations(filteredData, columns);

  const { t } = useTranslation();

  const singleColums = useMemo(() => {
    return columns.filter((column) => !column.groupHeader);
  }, [columns, allSelected]);

  return (
    <TableContainer className="flex flex-col justify-between grow">
      <Table stickyHeader aria-label="sticky table" size="small" sx={{ width: width }}>
        <TableHead sx={{ position: 'sticky', top: 0, zIndex: 2 }}>
          <HeaderRow
            {...sortingProps}
            columns={columns}
            onSelectAll={() => onSelectAll()}
            checked={allSelected}
            selectable={selectable}
          />
          <FilterBar
            {...filterProps}
            isOpen={showFilters}
            columns={singleColums}
            selectable={selectable}
          />
        </TableHead>
        <TableBody>
          {paginatedData?.map((rowData, index) => {
            const isSelected =
              !!selectedRows.find((row) => row[rowKey] === rowData?.[rowKey]) || allSelected;
            return (
              <TableRow
                key={`${rowData[rowKey]}${index}`}
                sx={{
                  transition: 'background-color.3s',

                  '&:hover': {
                    backgroundColor: 'rgba(50,50,50,0.1)',
                  },
                  height: 50,
                }}
              >
                {selectable && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      id={'selection'}
                      value={'value'}
                      key={`checkbox-${rowData[rowKey]}-${isSelected}`}
                      defaultChecked={isSelected}
                      onChange={() => onSelect(rowData)}
                    />
                  </TableCell>
                )}
                {singleColums.map((column, i) => {
                  const cellValue = getCellValue(rowData, column);
                  const key = `${column.name}${i}`;

                  if (column.renderer) {
                    return (
                      <TableCell key={key} align="center" className={column.className}>
                        {column.renderer(rowData)}
                      </TableCell>
                    );
                  }
                  if (column.compliance) {
                    return (
                      <TableCell key={key} align="center">
                        <ComplianceCell data={rowData} />
                      </TableCell>
                    );
                  }
                  if (column.risk) {
                    return (
                      <TableCell key={key} align="center">
                        <RiskCell rowData={rowData} columnName={column.name} />
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      key={key}
                      align="center"
                      onClick={() => column.onClick && column.onClick(rowData)}
                    >
                      <Typography fontSize={13} noWrap>
                        {cellValue || <EmptyCellIndicator />}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
        <AggregationsRow aggregations={aggregations} columns={columns} />
      </Table>

      {!loading && sortedData.length === 0 && (
        <NoDataAvailable text1={t('HOME.NO_DATA_NOTE')} text2={t('HOME.ADD_GROWER_TEXT')} />
      )}
      <CustomTablePagination {...paginationProps} />
    </TableContainer>
  );
};

export default memo(PredimaTable) as typeof PredimaTable;
