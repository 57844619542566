import client, { schemas } from '../client';
const Grower = new (class {
  async getFieldViewData(user_email) {
    const { data, response } = await client.POST('/fetch_field_view_data', {
      body: user_email,
    });

    return { data, response };
  }

  async addGrower(body: schemas['GrowerIn']) {
    const { response } = await client.POST('/add_grower', { body });
    return response;
  }

  async updateGrower(body: schemas['GrowerUpdateIn']) {
    const { response } = await client.PUT('/update_grower', { body });
    return response;
  }

  async addOrder(body: schemas['OrderIn']) {
    const { response } = await client.POST('/create_order', { body });
    return response;
  }

  async updateOrder(body: schemas['OrderDetailsUpdateIn']) {
    const { response } = await client.PUT('/update_order_details', { body });
    return response;
  }

  async getAllGrowers() {
    const { data } = await client.GET('/all_growers', {});
    return data;
  }

  async deleteGrowers(body: schemas['GrowerDeleteIn']) {
    const { response } = await client.POST('/grower_delete', { body });
    return response;
  }

  async updateFieldviewData(body: schemas['FieldUpdateIn'][]) {
    const { response } = await client.PUT('/update_fields', { body });
    return response;
  }

  // this props type is custom-set as the OpenAPI one says array of strings or null
  // and null would be clearly wrong
  async inviteGrower({ to }: { to: string[] }) {
    const { response } = await client.POST('/send_email', { body: { to } });
    return response;
  }

  async setActualSprayDate(body: schemas['TreatmentIn']) {
    const { response } = await client.POST('/add_treatment_application_date', { body });
    return response;
  }

  async getNotifications() {
    const { data } = await client.GET(`/notification_list`, {});
    return data;
  }

  async markAsRead(body: schemas['NotificationUpdateIn']) {
    const { response } = await client.POST('/update_notifications', { body });
    return response;
  }

  async deleteTreatments(body: schemas['DeleteTreatmentIn']) {
    const { response } = await client.POST('/delete_product_application', { body });
    return response;
  }

  // TODO: field id is numeric
  async addObservationDate(field_id: number, body: schemas['UpdateBbch30In']) {
    const { response } = await client.POST('/update_observations/{field_id}', {
      params: { path: { field_id: field_id } },
      body,
    });
    return response;
  }

  async addComment(body: schemas['ComplianceCommentIn']) {
    const { response } = await client.POST('/add_or_update_compilance_comments', { body });
    return response;
  }

  async getComplianceComments(fv_id: string) {
    const { data } = await client.GET('/get-compliance_comments/{fv_id}', {
      params: { path: { fv_id: fv_id } },
    });
    return data;
  }

  async addProduct(body: schemas['ProductDeliveryIn']) {
    const { response } = await client.POST('/add_or_update_product_delivery', { body });
    return response;
  }

  async deleteProduct(body: schemas['DeleteProductDeliveryIn']) {
    const { response } = await client.POST('/delete_product_delivery', { body });
    return response;
  }
})();

export default Grower;
