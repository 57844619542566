import { parse, isValid, isAfter, isBefore, startOfDay, isEqual } from 'date-fns';
import { useState, useEffect, useMemo } from 'react';
import { getCellValue, stringToBoolean } from '../utils/commonFunctions';
import useLocalStorage from './localStorage/useLocalStorage';
import { dateFormats, locales } from '../components/Forms/FormDateInput';

interface DataItem {
  [key: string]: any;
}

const useFilteredList = <T extends DataItem>(initialData: T[], columns) => {
  const [locale] = useLocalStorage('locale', 'fr');
  const [data, setData] = useState<T[]>([]);
  const [criteria, setCriteria] = useState({});

  useEffect(() => {
    if (initialData) setData(initialData);
  }, [initialData]);

  const filteredData = useMemo(() => {
    return data.filter((dataRow) => {
      return columns.every((column) => {
        const filterCriteria = criteria[column.name];
        const searchInput =
          typeof filterCriteria === 'object'
            ? filterCriteria
            : String(filterCriteria || '').toLowerCase();

        const cellValue = getCellValue(dataRow, column);

        // Handle no filter case
        if (!searchInput || searchInput === 'all') return true;

        // Handle dropdown filters
        if (column.dropdown) return searchInput === cellValue;

        // Handle boolean filters
        if (column.boolean) return stringToBoolean(searchInput) === cellValue;

        // Date filter handling with date-fns
        if (typeof filterCriteria === 'object') {
          const { range, fromDate, toDate, applied } = filterCriteria;

          // Only apply filter if explicitly marked as applied
          if (!applied) return true;
          const cellInputFormat = dateFormats[locales[locale]];

          try {
            // Parse cell value (DD-MM-YYYY) and filter dates (YYYY-MM-DD)
            const cellDate = cellValue
              ? startOfDay(parse(cellValue, cellInputFormat, new Date()))
              : null;

            const filterFrom = fromDate //DateFilter originated dates use internal standard formatting.
              ? startOfDay(parse(fromDate, 'yyyy-MM-dd', new Date()))
              : null;

            const filterTo = toDate ? startOfDay(parse(toDate, 'yyyy-MM-dd', new Date())) : null;

            if (range) {
              if (!cellDate || !filterFrom || !filterTo) return false;
              if (!isValid(cellDate) || !isValid(filterFrom) || !isValid(filterTo)) return false;

              return (
                (isAfter(cellDate, filterFrom) || isEqual(cellDate, filterFrom)) &&
                (isBefore(cellDate, filterTo) || isEqual(cellDate, filterTo))
              );
            }
            return !cellValue;
          } catch (error) {
            console.error('Date parsing failed:', error);
            return false;
          }
        }

        // Default text filter
        if (!cellValue) return false;
        return String(cellValue).toLowerCase().includes(searchInput);
      });
    });
  }, [data, columns, criteria]);

  // Rest of the hook remains unchanged
  const handleUpdateFilter = (columnName) => (event) => {
    setCriteria((prev) => ({ ...prev, [columnName]: event.target.value }));
  };

  const handleUpdateDateFilter = (columnName, criteria) => {
    setCriteria((prev) => ({ ...prev, [columnName]: criteria }));
  };

  const handleClearFilters = () => setCriteria({});

  return {
    filteredData,
    handleUpdateFilter,
    handleClearFilters,
    handleUpdateDateFilter,
    criteria,
  };
};

export default useFilteredList;
