import { classNames } from '../../Pages/reviewDetails/components/classNames';

const Layout3Cols = ({ children, title }) => {
  return (
    <div className="flex flex-col h-full min-h-[600px] Layout3cols grow">
      <div className="flex items-center mt-4 ml-4">
        <span className="text-gray-400 text-xs">&#8226;</span>
        <span className={`${classNames.noteText} ml-2`}>{title}</span>
      </div>
      <div className="flex justify-between w-full h-full grow">
        {children[0]}
        {children[1]}
        {children[2]}
      </div>
    </div>
  );
};

export default Layout3Cols;
