import React from 'react';
import { classNames } from '../Pages/reviewDetails/components/classNames';
import EditButton from '../Pages/reviewDetails/components/EditButton';

const Card = ({
  children,
  title,
  icon = <></>,
  footerContent = <></>,
  headerContent = null,
  hfull = false,
  className = '',
  titleClassName = '',
  margin = 'm-4',
  padding = 'p-4',
  gap = 'gap-4',
  iconPosition = 'end',
  editable = false,
  onEditClick = () => {},
}) => {
  const defaultHeader = (
    <div className={`${classNames.sectionTitle} ${titleClassName} group`}>
      <div className={'flex gap-4 items-center'}>
        {iconPosition === 'start' ? icon : null}
        <p className={classNames.sectionTitleText}>{title}</p>
        {iconPosition === 'end' ? icon : null}
      </div>
      {editable ? <EditButton onClick={onEditClick} /> : null}
    </div>
  );

  return (
    <div
      className={`${classNames.sectionContainer} ${hfull ? 'h-full' : ''} ${className} ${margin}`}
    >
      {headerContent ? headerContent : defaultHeader}
      <div className={`${classNames.cardContent} ${padding} ${gap}`}>{children}</div>
      {footerContent}
    </div>
  );
};

export default Card;
