import React from 'react';
import ArvalisLogo from '../icons/ArvalisLogo';
import { useTranslation } from 'react-i18next';
import useCountryCode from '../../hooks/useCountryCode';

const classNames = {
  disclaimerRow: 'flex gap-1 p-1 items-center ml-[1em]',
  arvalisLogo: 'h-5 w-20 mt-[2px]',
  disclaimer: 'text-xs text-gray-500',
};

const ArvalisDisclaimer = () => {
  const { t } = useTranslation();

  const countryCode = useCountryCode();

  if (!countryCode.FR) return null;

  return (
    <span className={classNames.disclaimerRow}>
      <span className={classNames.disclaimer}>{t('ARVALIS.DISCLAIMER')}</span>
      <ArvalisLogo className={classNames.arvalisLogo} />
      <span className={classNames.disclaimer}>{t('ARVALIS.DISCLAIMER2')}</span>
    </span>
  );
};

export default ArvalisDisclaimer;
