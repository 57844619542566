import React from 'react';
import SubscriptionTable from '../../Table/SubscriptionTable';
import DynamicTabs from '../../Layout/shared/DynamicTabs';
import { useTranslation } from 'react-i18next';

const Subscription = () => {
  const { t } = useTranslation('', { keyPrefix: 'PAGES.TABS' });
  const SUBSCRIPTION_LIST = t('SUBSCRIPTION_LIST');
  const PRODUCT_DELIVERY = t('PRODUCT_DELIVERY');

  const tabs = [
    {
      id: 'subscription_list',
      name: SUBSCRIPTION_LIST,
      children: <SubscriptionTable />,
    },
    // {
    //   id: 'product_delivery',
    //   name: PRODUCT_DELIVERY,
    //   children: <ProductDelivery />,
    // },
  ];

  return <DynamicTabs tabs={tabs} />;
};

export default Subscription;
