import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../utils/commonFunctions';
import { useStateContext } from '../../utils/stateContext';
import PredimaTable, { PredimaTableColumn } from '../PredimaTable';
import TableControls from './TableControls';
import { Link, useNavigate } from 'react-router-dom';
import GrowerAPI from '../../api/grower';
import { toast } from '@tcc/labz-design-system';
import ComplianceCell from './ComplianceCell';
import { useTableSelection } from '../../hooks/useTableSelection';
import useSubscription from '../../hooks/useSubscription';
import { Subscription } from '../../types/subscription';

const bgbutton = 'text-white bg-primary-500 hover:bg-primary-600 hover:border-primary-600';

const classNames = {
  button: 'rounded-md px-4 py-1 text-sm font-semibold',
  addButton: `flex h-8 items-center ${bgbutton}`,
  loadingOverlay:
    'absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 z-30 h-full',
  container: 'flex flex-col w-full justify-between',
};

const SubscriptionTable = () => {
  const {
    getAllGrowers: { data, refetch },
    selectedSubscriptionItem: [_, setSelectedSubscriptionItem],
  } = useStateContext();

  const { subscriptionList = [], lastUpdated } = useSubscription(data);

  const {
    selectedItems: selectedGrowers,
    allSelected,
    handleSelect: handleGrowerSelect,
    handleSelectAll,
    clearSelection,
  } = useTableSelection({
    data: subscriptionList,
    idKey: 'uuid',
  });

  const [isFiltering, setIsFiltering] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSubscriptionCellClick = (rowData) => {
    setSelectedSubscriptionItem(rowData);
    if (rowData?.order) {
      navigate('/home/growerOverview');
      return;
    } else {
      navigate('/home/growerdetail');
    }
  };
  const columnWidth = '9.3%';

  const columns: Array<PredimaTableColumn<Subscription>> = [
    {
      name: 'distributor_name',
      label: t('SUBSCRIPTION.DISTRIBUTOR'),
      width: columnWidth,
      search: true,
      onClick: handleSubscriptionCellClick,
    },
    {
      name: 'created_user.name',
      label: t('SUBSCRIPTION.SALES_REP_NAME'),
      width: columnWidth,
      search: true,
      onClick: handleSubscriptionCellClick,
    },
    {
      name: 'contact_name',
      label: t('SUBSCRIPTION.GROWER'),
      width: columnWidth,
      search: true,
      onClick: handleSubscriptionCellClick,
    },
    {
      name: 'status',
      label: t('SUBSCRIPTION.STATUS'),
      width: columnWidth,
      search: true,
      onClick: handleSubscriptionCellClick,
    },
    {
      name: 'start_date',
      label: t('SUBSCRIPTION.START_DATE'),
      width: columnWidth,
      date: true,
      onClick: handleSubscriptionCellClick,
    },
    {
      name: 'app_login',
      label: t('SUBSCRIPTION.APP_LOGIN'),
      width: columnWidth,
      valueGetter: (rowData) => (rowData.app_login ? t('GENERIC.YES') : t('GENERIC.NO')),
      onClick: handleSubscriptionCellClick,
    },
    {
      name: 'name',
      label: t('SUBSCRIPTION.FARM'),
      width: columnWidth,
      search: true,
      onClick: handleSubscriptionCellClick,
    },
    {
      name: 'field_area',
      label: t('SUBSCRIPTION.HA'),
      width: columnWidth,
      search: true,
      aggregate: true,
      onClick: handleSubscriptionCellClick,
      valueGetter: (rowData) => formatNumber(rowData.order?.order_total_ha),
    },
    {
      name: 'price',
      label: t('SUBSCRIPTION.PRICE'),
      width: columnWidth,
      search: true,
      aggregate: true,
      onClick: handleSubscriptionCellClick,
      valueGetter: (rowData) => formatNumber(rowData.order?.order_price),
    },
    {
      name: 'order.offer.name',
      label: t('SUBSCRIPTION.OFFER'),
      width: columnWidth,
      search: true,
      onClick: handleSubscriptionCellClick,
    },
    {
      name: 'consent',
      label: t('SUBSCRIPTION.COMPLIANCE'),
      width: columnWidth,
      valueGetter: (rowData) => !!rowData?.compliance,
      renderer: (rowData) => <ComplianceCell data={rowData} />,
    },
  ];

  const AddGrower = () => {
    return (
      <Link className={`${classNames.button} ${classNames.addButton}`} to={`/home/addgrower`}>
        {t('HOME.ADD_GROWER')}
      </Link>
    );
  };

  const deleteGrowers = useCallback(() => {
    if (selectedGrowers.length < 1) {
      toast.error(t('ALERT.MSG5'));
      return;
    }

    GrowerAPI.deleteGrowers({ id: selectedGrowers.map(({ id }) => id) }).then((response) => {
      if (response.status === 200) {
        toast.success(t('ALERT.MSG6'));
        clearSelection();
        refetch();
      } else {
        toast.error(t('ALERT.MSG5'));
      }
    });
  }, [selectedGrowers, t, refetch]);

  return (
    <div className={classNames.container}>
      <TableControls
        lastUpdated={lastUpdated}
        ActionButton={AddGrower}
        onReload={refetch}
        onToggleFilters={() => setIsFiltering(!isFiltering)}
        isFiltering={isFiltering}
        onDelete={deleteGrowers}
      />
      <PredimaTable<Subscription>
        rowKey="uuid"
        selectable={true}
        data={subscriptionList}
        columns={columns}
        loading={false}
        showFilters={isFiltering}
        onSelect={handleGrowerSelect}
        onSelectAll={handleSelectAll}
        allSelected={allSelected}
        selectedRows={selectedGrowers as Subscription[]}
      />
    </div>
  );
};
export default SubscriptionTable;
